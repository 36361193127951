<template lang="pug">
ButtonWithIcon.line-call-button.grey.background--text(v-bind='$attrs'
  v-on='$listeners'
  depressed
  :icon='icon'
  :href='lineLink')
  | LINE で無料相談
</template>

<script>
import { CONTACT_LINE_URL } from '@/constants'
import ButtonWithIcon from '@/components/molecules/ButtonWithIcon'

export default {
  components: {
    ButtonWithIcon
  },
  data () {
    return {
      lineLink: CONTACT_LINE_URL,
      icon: 'icon-line'
    }
  }
}
</script>
