<template lang="pug">
v-select(:items='items'
  v-bind='$attrs'
  v-on='$listeners'
  v-model='internalValue'
  item-text='label'
  item-value='value'
  return-object
  background-color='white'
  outlined)
</template>

<script>
export default {
  props: {
    value: Object,
    items: {
      type: Array
    }
  },
  computed: {
    internalValue: {
      get () {
        return this.value
      },
      set (newValue) {
        if (this.value !== newValue) {
          this.$emit('input', newValue)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
