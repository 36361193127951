<template lang='pug'>
Content(showFooter=true)
  template(#header)
    colored-text
      template(#secondary) 死亡保険で準備しておくべき金額は？

  v-container
    chip.ma-1 遺族が生きていくために必要な支出
    | と
    chip.ma-1 遺族に今後見込まれる収入＋貯蓄などの資産
    | を比較したときに、不足する分が
    v-chip.ma-1.white--text(label color='primary2') 必要保障額
    | として死亡保険で準備しておきたい金額です。

  break-down-of-coverage

  v-container
    p 各項目がいくらになるのかは年齢や家族構成、生活環境等に応じて大きく異なります。
    p 家族が安心して生きていくためにどの程度のお金を準備しておきたいか、将来のライフプランを描いて必要保障額を考えてみましょう。

  about-expenditure.px-3(
    @toggle='onToggleAccordionAboutExpenditure')

  v-container
    v-row(no-gutters)
      v-col.mb-3
        v-btn.primary2.title.white--text(
          outlined
          block
          min-height='80'
          @click='movePageInDirectory("recommend")')
          v-icon(size='40px').mr-4 icon-star
          span.btn-text {{ freelancerLabel }}におすすめの保険！
    v-row(no-gutters)
      v-col.mb-3
        v-btn.primary2.title.mb-5.white--text(
          outlined
          block
          min-height='80'
          @click='movePageInDirectory("contact")')
          v-icon(size='40px').mr-4 icon-bubble
          | 保険相談する
</template>

<script>
import Chip from '@/components/atoms/Chip'
import ColoredText from '@/components/molecules/ColoredText'
import AccordionListItem from '@/components/molecules/AccordionListItem'
import BreakDownOfCoverage from '@/components/organisms/BreakDownOfCoverage'
import Content from '@/components/organisms/Content'
import AboutExpenditure from '@/components/organisms/AboutExpenditure'
import page from '@/mixin/page'
import labels from '@/mixin/labels'

export default {
  mixins: [page, labels],
  components: {
    Chip,
    ColoredText,
    Content,
    AboutExpenditure,
    BreakDownOfCoverage,
    AccordionListItem
  },
  methods: {
    onToggleAccordionAboutExpenditure ({ isClosed }) {
      this.sendLog(`${isClosed ? 'close' : 'open'}-accordion-about-expenditure`)
    }
  }
}
</script>
