<template lang='pug'>
v-container.cases.accent.mb-3
  colored-text.text-center.headline
    | どのような場合に保険金が
    br
    | 支払われますか？
  v-row
    v-col
      CaseCard(image='/img/health.svg')
        template(#text) 死亡した場合
    v-col
      CaseCard(image='/img/disability.svg')
        template(#text) 高度障害の場合

  v-row(dense)
    v-col.text-center.title
      a.default--text(href='/pdf/R-A020-13-04.pdf' target='_blank') 収入保障保険の商品パンフレットはこちら
      div 商品の詳細につきましては、「契約概要」「注意喚起情報」「ご契約のしおり・約款」などをご確認ください。
</template>

<script>
'use strict'

import ColoredText from '@/components/molecules/ColoredText'
import CaseCard from '@/components/molecules/CaseCard'

export default {
  components: {
    CaseCard,
    ColoredText
  }
}
</script>
