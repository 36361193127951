<template lang="pug">
v-container.accent
  v-row(dense)
    v-col
      v-card(flat height='100%' color='white')
        v-container.fill-height
          v-row(justify='center')
            v-col
              v-card-title.pa-3.pb-0.primary2--text 遺族の支出
              v-card-text.pa-3.pt-0.subtitle-1
                | 配偶者の生活費
                br
                | お子様の生活費
                br
                | 教育費
                br
                | 葬儀代など
    v-col
      v-container.pa-0
        v-row(no-gutters)
          v-col
            v-card.mb-2(flat color='white')
              v-card-title.pa-3.pb-0.primary2--text 遺族の収入
              v-card-text.pa-3.pt-0.subtitle-1
                | 遺族年金
                br
                | 配偶者の収入など
        v-row(no-gutters)
          v-col
            v-card.mb-2(flat color='white')
              v-card-title.pa-3.pb-0.primary2--text 遺族の資産
              v-card-text.pa-3.pt-0.subtitle-1
                | 貯蓄など
        v-row(no-gutters)
          v-col
            v-card.white--text(flat color='primary2')
              v-container.fill-height
                v-row(justify='center' align='center')
                  v-col.title(align='center')
                    div.mb-2 不足分
                    v-icon(color='white' size=32).mb-2 mdi-equal
                    div 必要保障額
</template>

<script>
import Header from '@/components/molecules/Header'
import Footer from '@/components/organisms/Footer'

export default {
  components: {
    Header,
    Footer
  },
  methods: {
    getLeftColClass (index, { length }) {
      return {
        'mb-2': index < (length - 1)
      }
    }
  }
}
</script>
