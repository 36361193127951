<template lang="pug">
a.privacy-policy-link(:href='url' target='_blank')
  slot {{ text }}
</template>

<script>
import { PRIVACY_POLICY_URL } from '@/constants'

export default {
  props: {
    text: {
      type: String,
      default: 'エヌエヌ生命の個人情報保護方針をご確認ください'
    }
  },
  computed: {
    url () {
      return PRIVACY_POLICY_URL
    }
  }
}
</script>
