<template lang='pug'>
v-layout.contact-form-area(flex wrap column)
  ButtonWithIcon.contact-form-button(
      outlined
      color='primary'
      :icon='icon'
      @click.stop='onClickButton') フォームで無料相談

  Dialog(v-model='dialog')
    ColoredText.contact-form-title.mb-3
      span(v-show='success') お問い合わせありがとうございます
      span(v-show='!success') お気軽にご相談ください

    v-container.pa-0.success-message(v-show='success')
      p お問い合わせ内容を確認させていただき、後ほど担当者よりご連絡させていただきます。
      p 恐れ入りますが、今しばらくお待ちいただけますよう、宜しくお願い申し上げます。
      v-layout.dialog-footer.justify-end
        a.secondary--text.close-link(@click.stop='hideDialog') 閉じる

    ContactForm(v-show='!success' v-bind='$attrs' v-on='$listeners' @success='onSuccess' :hasCancelButton='true' @cancel='hideDialog')
</template>

<script>
import ButtonWithIcon from '@/components/molecules/ButtonWithIcon'
import Dialog from '@/components/atoms/Dialog'
import ColoredText from '@/components/molecules/ColoredText'
import ContactForm from '@/components/organisms/ContactForm'

export default {
  components: {
    ButtonWithIcon,
    Dialog,
    ColoredText,
    ContactForm
  },
  data () {
    return {
      icon: 'icon-form',
      dialog: false,
      success: false
    }
  },
  methods: {
    onClickButton () {
      this.dialog = true
      this.success = false
      this.$emit('click-button')
    },
    hideDialog () {
      this.dialog = false
    },
    onSuccess () {
      this.success = true
    }
  }
}
</script>

<style lang='scss' scoped>
.contact-form-title {
  font-size: 1.23rem;
}
.contact-form-area {
  background: #fff;
}
</style>
