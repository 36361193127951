import validate from 'validate.js'

function requiredMessage (key) {
  return `${key}を入力してください。`
}

const simulationConstraints = {
  age: {
    presence: {
      message: requiredMessage('年齢')
    },
    type: 'integer'
  },
  partnerAge: {
    presence: {
      message: requiredMessage('配偶者の年齢')
    },
    type: 'integer'
  },
  childAges: {
    type: 'array'
  },
  annualIncome: {
    presence: {
      message: requiredMessage('年収')
    },
    type: 'integer'
  }
}

const contactConstraints = {
  name: {
    presence: requiredMessage('名前')
  },
  email: {
    presence: {
      message: requiredMessage('メールアドレス')
    },
    email: {
      message: 'メールアドレスが不正です。'
    }
  },
  comment: {
    type: 'string'
  }
}

// return error messages for wrong values.
export default {
  simulation (data) {
    return validate(data, simulationConstraints)
  },
  contact (data) {
    return validate(data, contactConstraints)
  }
}
