const EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const PHONE_NUMBER_REGEXP = /^\d{2,5}-\d{1,4}-\d{4}$/

export const makeisNotEmptyRule = (name) => {
  return (value) => {
    return isNotEmpty(value, name)
  }
}

export const isNotEmpty = (value, name = '') => {
  return value === 0 || !!value || (name ? `${name}を入力して下さい。` : '必須項目です。')
}

export const isNumber = (value) => {
  // const number = Number(value)
  return /^\d+$/.test(value) || '数値を入力してください。'
}

export const validateEmail = (value) => {
  return EMAIL_REGEXP.test(value) || 'メールアドレスが不正です。'
}

export const validatePhonenumber = (value) => {
  return (!value || PHONE_NUMBER_REGEXP.test(value)) || '電話番号が不正です。'
}
