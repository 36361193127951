<template lang='pug'>
v-card(outlined)
  v-container.pa-0
    v-container.py-0
      v-row(align='center')
        v-col.title.primary3--text(width=320) {{ label }}
        v-col.secondary--text {{ receivedAmountLabel }}
          br
          | 受取額(約{{ receivedAmount | formatJPPriceToTenThousandUnit }})との差
    v-divider
    v-row.title(align='center')
      v-col 約{{ amount | formatJPPriceToTenThousandUnit }}
      v-col.primary3--text 約{{ difference | formatJPPriceToTenThousandUnit }}
</template>

<script>
'use strict'

import { formatJPPriceToTenThousandUnit } from '@/utils/formatter'

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    amount: {
      type: [String, Number],
      required: true
    },
    receivedAmountLabel: {
      type: String,
      required: true
    },
    receivedAmount: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    difference () {
      return this.amount - this.receivedAmount
    }
  },
  filters: {
    formatJPPriceToTenThousandUnit (value) {
      return formatJPPriceToTenThousandUnit(value, 10)
    }
  }
}
</script>
