<template lang='pug'>
v-app#app
  v-container.pa-0
    div#logo-area
      img(src='@/assets/logo.png')
  v-main.content-area
    transition(name='fade', mode='out-in')
      router-view
</template>

<script>
import labels from '@/mixin/labels'

export default {
  mixins: [labels]
}
</script>

<style lang='scss' scoped>
#app {
  display: block;
  margin: 0 auto;
  max-width: 1185px;
}

#logo-area {
  position: relative;

  img {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 130px;
    height: 90.47px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .15s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
