'use strict'

import Vue from 'vue'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing, {
  networks: {
    hatena: 'http://b.hatena.ne.jp/add?url=@u&title=@t'
  }
})
