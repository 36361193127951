<template lang='pug'>
v-layout.contact-buttons(fluid column)
  PhoneButton.mb-3(@click="onClickButton('phone')") 電話で無料相談 (平日9:00-17:00)
  LineButton.mb-3(@click="onClickButton('line')")
  MailButton.mb-3(@click="onClickButton('mail')")
  ContactButton(@success='onSuccess' @click-button="onClickButton('form')")
</template>

<script>
import PhoneButton from '@/components/molecules/PhoneButton'
import LineButton from '@/components/molecules/LineButton'
import MailButton from '@/components/molecules/MailButton'
import ContactButton from '@/components/organisms/ContactButton'
import page from '@/mixin/page'

export default {
  components: {
    PhoneButton,
    LineButton,
    MailButton,
    ContactButton
  },
  props: {
    pageName: String
  },
  mixins: [page],
  methods: {
    onClickButton (name) {
      const eventName = `click-${name}-button` + (this.pageName ? `-in-${this.pageName}-page` : '')

      this.sendLog(eventName)
    },
    onSuccess () {
      this.$emit('success')
    }
  }
}

</script>
