// NOTE
// Implement only units used in this app.
const UNIT_DICTIONARY = {
  1: '',
  10000: '万',
  100000000: '億'
}

export const formatJPPrice = (price = 0, divide = 1, base = 1) => {
  const unit = UNIT_DICTIONARY[divide] || UNIT_DICTIONARY[1]
  const formattedPrice = Math.round((price / divide) * base) / base
  return `${formattedPrice.toLocaleString()}${unit}円`
}

export const formatJPPricePerMonth = (price) => {
  return `${formatJPPrice(price)}/月`
}

export const formatJPPricePerYear = (price) => {
  return `${formatJPPrice(price)}/年`
}

export const getSexLabel = (code) => {
  const MAP = {
    1: '男性',
    2: '女性'
  }

  return MAP[code] || ''
}

export const formatJPPriceToTenThousandUnit = (price, base) => formatJPPrice(price, 10000, base)

const DICTIONARY = [
  '万',
  '億',
  '兆',
  '京',
  '垓',
  '杼',
  '穰',
  '溝',
  '澗',
  '正',
  '載',
  '極',
  '恒河沙',
  '阿僧祇',
  '那由他',
  '不可思議',
  '無量大数'
]

const convertStringToLocaleString = (v) => {
  const parsed = parseInt(v, 10)

  return isNaN(parsed) ? v : parsed.toLocaleString()
}
export const formatJPUnitPrice = (price = 0) => {
  const str = Math.round(price).toString()
  let converted = ''
  let count = 0
  let ptr = 0

  for (let i = (str.length - 1); i >= 0; i--) {
    const char = str.charAt(i)
    converted = char + converted

    count++

    if (((count % 4) === 0) && (i !== 0)) {
      converted = DICTIONARY[ptr++] + converted
    }
  }

  // Remove '0000'
  const removed = converted.replace(/0{4}/g, '')
  const splitted = removed.match(/(\d+)|(\D+)/g)

  const formatted = splitted.reduce((a, b, i) => {
    return (i === 1 ? convertStringToLocaleString(a) : a) + convertStringToLocaleString(b)
  })

  return `${formatted}円`
}
