'use strict'

export default {
  props: {
    icon: {
      type: [String, Array],
      required: true
    }
  },
  computed: {
    isFontAwesomeIcon () {
      return Array.isArray(this.icon)
    }
  }
}
