<template lang='pug'>
v-container.pa-0
  div.pa-0(style='position: relative;')
    v-card.accent.half-background-for-button-group(flat height='100' width='100%')
    example-toggle-button-group(v-model='currentIndex' :items='examples')
  v-container.accent
    v-container.text-sm-center.white.mb-3.pa-6.supplement--text
      div
        strong 月額保険料
        span 相当額
        label-with-loading(v-model='estimatedAmount')
          span.display-1 {{ monthlyAmount | toLocaleString }}
          span.title 円/月 (年額 {{ estimatedAmount | toLocaleString }}円)
        div を年間一括払いで、{{ termInsured }}年間払いただいた場合
      v-row.my-5(no-gutters)
        v-col(align='center')
          v-icon(size=32 color='secondary') mdi-arrow-down
      strong 何か「万一」があったときは・・・
      div.mb-3 このように保険金が支払われます。

      template(v-for='item, index in paymentCaseItems')
        v-row(dense justify='center' align='center')
          v-col(sm=6)
            v-divider
        v-row.text-left(dense justify='center' align='center')
          v-col(sm=3 cols=7)
            div.primary--text.title {{ item.causeText }}
            div.subtitle-2 {{ item.caseText }}
          v-col(sm=2 align='right')
            div.amount.primary--text.font-weight-bold
              span.display-1 {{ item.amount | devideBy10000 | toLocaleString }}
              span.title 万円
    div.supplement--text.text-body-2
      | ＊1 この保険には支払削減期間があります。ご契約日からその日を含めて1年以内に死亡保険金または重度疾病死亡保険金のお支払事由に該当した場合、お支払いする金額が削減されます。
      br
      | ＊2 重度疾病死亡保険金または災害死亡保険金が支払われる場合は死亡保険金はお支払いしません。
</template>

<script>
'use stict'

import LabelWithLoading from '@/components/molecules/LabelWithLoading'
import ExampleToggleButtonGroup from '@/components/molecules/ExampleToggleButtonGroup'
import { formatJPPriceToTenThousandUnit } from '@/utils/formatter'
import { mapFields } from 'vuex-map-fields'
import example from '@/mixin/example'

export default {
  props: {
    termKindCode: {
      type: [String, Number],
      required: true
    },
    period: {
      type: [String, Number],
      required: true
    },
    examples: {
      type: Array,
      required: true
    }
  },
  mixins: [example],
  components: {
    LabelWithLoading,
    ExampleToggleButtonGroup
  },
  mounted () {
    this.updateStatus()
  },
  methods: {
    async updateStatus () {
      this.isLoading = true
      try {
        const { result } = await this.$store.dispatch('calcInsurancePremium', {
          product: this.product,
          inputs: {
            sex: this.sex,
            termInsured: this.termInsured,
            age: this.age,
            baseAmount: this.baseAmount
          }
        })

        this.estimatedAmount = result[0].estimatedAmount
      } catch (error) {
        this.errorMessage = error.response.data.message
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    currentIndex () {
      this.updateStatus()
    }
  },
  computed: {
    ...mapFields(['simulationInput.age']),
    baseAmount () {
      return this.examples[this.currentIndex].baseAmount
    },
    termInsured () {
      return (this.shouldUseExpirationAge === true) ? this.periodByExpirationAge : this.period
    },
    monthlyAmount () {
      return Math.round(this.estimatedAmount / 12)
    },
    paymentCaseItems () {
      const { baseAmount } = this
      return [
        {
          causeText: '重度疾病',
          caseText: '急性心筋梗塞または脳卒中で亡くなられた場合',
          amount: baseAmount
        },
        {
          causeText: '事故・感染症',
          caseText: '不慮の事故または感染症で亡くなられた場合',
          amount: baseAmount
        },
        {
          causeText: 'その他の死亡原因',
          caseText: '他の理由で亡くなられた場合',
          amount: baseAmount / 2
        }
      ]
    }
  },
  data () {
    return {
      estimatedAmount: 0,
      currentIndex: 0,
      product: 'sitp',
      sex: 1
    }
  },
  filters: {
    toLocaleString (value = 0) {
      return value.toLocaleString()
    },
    devideBy10000 (value) {
      return value / 10000
    },
    formatJPPriceToTenThousandUnit
  }
}
</script>

<style>
.half-background-for-button-group {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
