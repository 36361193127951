<template lang='pug'>
v-textarea(v-bind='$attrs'
  v-on='$listeners'
  background-color='white'
  outlined)
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
