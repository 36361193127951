<template lang="pug">
div.pa-0.accordion(
  ref='toggleArea'
  :class='toggleAreaClass'
  :style='toggleAreaStyles')
  slot
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    transitionDelay: {
      type: Number,
      default: 0.25
    },
    defaultVisiblePatio: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      scrollHeight: 0
    }
  },
  mounted () {
    this.scrollHeight = this.$refs.toggleArea.scrollHeight
  },
  computed: {
    defaultHeight () {
      return this.scrollHeight * this.defaultVisiblePatio
    },
    isClosed: {
      set (value = false) {
        this.$emit('isClosed', value)
      },
      get () {
        return this.value
      }
    },
    toggleAreaClass () {
      return this.isClosed ? 'closed' : 'opened'
    },
    toggleAreaStyles () {
      return {
        maxHeight: `${
          this.isClosed ? this.defaultHeight : this.scrollHeight + 1028
        }px`,
        transition: `${this.transitionDelay}s ease-in-out`
      }
    }
  }
}
</script>

<style scoped>
.accordion {
  position: relative;
  overflow: hidden;
}
</style>
