<template lang='pug'>
v-form.simulation-form(
  v-model='valid'
  ref='form')

  OutlinedTextField(
    label='お名前'
    placeholder='エヌエヌ太郎'
    v-model='userName'
    :rules='validationRules.userName'
    required
    :error-messages='propertyErrorMessages.userName')

  OutlinedTextField(
    label='メールアドレス'
    placeholder='nnnn@nnlife.co.jp'
    v-model='email'
    :rules='validationRules.email'
    required
    :error-messages='propertyErrorMessages.email')

  OutlinedTextArea(
    label='ご要望'
    placeholder='ご要望や相談内容が決まっている場合はこちらにご記入ください。',
    v-model='comment'
    :rules='validationRules.comment'
    :error-messages='propertyErrorMessages.comment')

  div.error-message.warning--text.mb-3(v-show='errorMessage') {{ errorMessage }}

  v-row(dense align='center')
    v-col
      v-btn.primary2.white--text.title(
        :disabled='!canSubmit'
        depressed
        height='55'
        large
        block
        :loading='pending'
        @click='submit') 送る
    v-col(align='right')
      a.mr-2.subtitle-1.label--text(text @click='$listeners.cancel') キャンセル
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import OutlinedTextField from '@/components/atoms/OutlinedTextField'
import OutlinedTextArea from '@/components/atoms/OutlinedTextArea'
import Button from '@/components/atoms/Button'
import { SERVER_ERROR_MESSAGE } from '@/constants'
import { validateEmail } from '@/validator/input-rules'

export default {
  data: function () {
    return {
      pending: false,
      valid: false,
      errorMessage: '',
      propertyErrorMessages: {
        userName: null,
        email: null,
        comment: null
      },
      privacyPolicyURL: 'https://www.nnlife.co.jp/company/policies/privacy',
      validationRules: {
        userName: [],
        email: [
          validateEmail
        ],
        comment: []
      }
    }
  },
  computed: {
    canSubmit () {
      return this.valid && this.isFilled && !this.pending
    },
    isFilled () {
      return !!this.userName && !!this.email
    },
    ...mapFields(['contactInput.userName', 'contactInput.email', 'contactInput.comment'])
  },
  async created () {
    const { userName, comment } = await this.$store.dispatch('getBusinessRule', 'contacts')

    const { validationRules } = this
    validationRules.userName = [
      this.createLengthRule({
        label: 'お名前',
        maxLength: userName.maxLength
      })
    ]
    validationRules.comment = [
      this.createLengthRule({
        label: 'ご要望',
        maxLength: comment.maxLength
      })
    ]
  },
  components: {
    OutlinedTextField,
    OutlinedTextArea,
    Button
  },
  methods: {
    createLengthRule ({ label, maxLength }) {
      return (v = '') => (v.length <= maxLength) || `${label}は${maxLength}字以内で入力して下さい。`
    },
    async submit () {
      const result = this.$refs.form.validate()

      if (result === false) {
        return
      }

      this.resetError()

      this.pending = true

      const inputData = {
        userName: this.userName,
        email: this.email,
        comment: this.comment
      }

      try {
        const $store = this.$store

        await $store.dispatch('postContact', inputData)

        $store.commit('DELETE_CONTACT_INPUT')

        this.$emit('success')
      } catch (error) {
        this.showErrorMessage(error.response.data.errors)
        this.pending = false
        this.$emit('failure')
      }
    },
    showErrorMessage (errors) {
      if (Array.isArray(errors) === false) {
        this.errorMessage = SERVER_ERROR_MESSAGE

        return
      }

      const errorMessages = this.propertyErrorMessages

      errors.forEach((error) => {
        const { property } = error

        if (Array.isArray(errorMessages[property]) === false) {
          errorMessages[property] = []
        }

        errorMessages[property].push(error.message)
      })
    },
    resetError () {
      this.errorMessage = null

      const propertyErrorMessages = this.propertyErrorMessages

      for (const key in propertyErrorMessages) {
        propertyErrorMessages[key] = null
      }
    }
  }
}
</script>

<style scoped>
.external-link {
  display: inline-block;
  text-align: left;
}
</style>
