<template lang='pug'>
v-container.pa-0
  v-row(no-gutters justify='center' align='center' style='height: 32px')
    v-col(:class='textClasses')
      slot
  v-row(no-gutters justify='center' align='center')
    v-col(justify='center' align='center')
      v-icon(:size='iconSize' :color='iconColor') mdi-circle
</template>

<script>
'use strict'

export default {
  props: {
    isSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    textColor () {
      return this.isSelected ? 'primary2' : 'grey'
    },
    iconColor () {
      return this.isSelected ? 'primary2' : 'grey'
    },
    textClasses () {
      const { isSelected } = this
      return {
        [`${this.textColor}--text`]: true,
        title: isSelected,
        'font-weight-bold': isSelected,
        'text-body-1': !isSelected
      }
    },
    iconSize () {
      return this.isSelected ? 17 : 13
    }
  }
}
</script>
