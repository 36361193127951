<template lang='pug'>
PreparationButton(
  v-bind='$attrs'
  v-on='$listeners'
  label='保険を申し込む'
  name='申し込み機能'
  nameOfDescription='保険のお申込み')
</template>

<script>
import PreparationButton from '@/components/molecules/PreparationButton'

export default {
  components: {
    PreparationButton
  }
}
</script>
