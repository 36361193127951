export default {
  computed: {
    shouldUseExpirationAge () {
      return (this.termKindCode === 2)
    },
    isSitp () {
      return this.product === 'sitp'
    },
    periodByExpirationAge () {
      if (this.shouldUseExpirationAge === false) {
        return
      }

      switch (true) {
        case (this.isOverMaxAge):
          // 年齢が55歳以上の場合は保険期間は10年固定
          return 10
        case (this.isBelowMinAge):
          // 年齢が30歳未満の場合は保険期間は35年固定
          return 35
        default:
          return (this.period - this.age)
      }
    },
    isOverMaxAge () {
      return this.age > 55
    },
    isBelowMinAge () {
      return (this.isSitp === false) && (this.age < 30)
    }
  }
}
