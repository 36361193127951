<template lang="pug">
Content(showFooter=true)
  template(#header)
    colored-text お問合せ
      template(#secondary) ありがとう
      template(#tertiary) ございました

  v-container
    div.text-center.mb-5
      | お問い合わせ内容を確認させていただき、後ほど担当者よりご連絡させていただきます。
    div.text-center.mb-5
      | 恐れ入りますが、今しばらくお待ちいただけますよう、宜しくお願い申し上げます。

</template>

<script>
import ColoredText from '@/components/molecules/ColoredText'
import Content from '@/components/organisms/Content'
export default {
  components: {
    ColoredText,
    Content
  }
}
</script>
