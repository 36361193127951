<template lang='pug'>
v-container.pa-0.example-toggle-button
  v-row(no-gutters justify='center')
    v-col.col-sm-4(v-for='item, index in items'
      :key='index'
      align='center')
      v-btn(
        width='100%'
        height='auto'
        color='transparent'
        depressed
        :class='getCaseButtonClass(index)'
        @click='onClickCaseButton(index)')
        v-container.transparent.pa-0(fill-height fluid)
          v-row(no-gutters)
            v-col
              v-icon.mb-2(size=60) {{ item.icon }}
              div.title {{ item.text }}
              div.body-1 {{ item.baseAmount | formatJPPriceToTenThousandUnit }}の例
              v-icon(v-show='isSelected(index)' width='20') mdi-menu-down
</template>

<script>
import { formatJPPriceToTenThousandUnit } from '@/utils/formatter'

export default {
  props: {
    value: {
      type: Number
    },
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  filters: {
    formatJPPriceToTenThousandUnit
  },
  computed: {
    internalValue: {
      get () {
        return this.value
      },
      set (newValue) {
        if (this.value !== newValue) {
          this.$emit('input', newValue)
        }
      }
    }
  },
  methods: {
    isSelected (index) {
      return (this.internalValue === index)
    },
    onClickCaseButton (index) {
      this.internalValue = index
    },
    getCaseButtonClass (index) {
      const isSelected = this.isSelected(index)

      return {
        'primary--text': isSelected,
        'supplement--text': !isSelected
      }
    }
  }
}
</script>
