<template lang="pug">
v-card.type-card.text-center(outlined)
  v-card-title.text-center
    v-row(:class='titleClass' justify="center") {{ name }}
  v-divider
  v-card-text.default--text
    slot
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'black'
    }
  },
  computed: {
    titleClass () {
      return `${this.color}--text`
    }
  }
}
</script>
