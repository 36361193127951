<template lang="pug">
v-container.py-5.text-center.accent.phone-contact-area
  div.label--text.mb-5 今すぐ電話でお問い合わせ
  v-btn.phone-call-button.py-3.note.background--text(
    width="100%"
    height="auto"
    depressed
    :href='telLink')
    font-awesome-icon.phone-icon.mr-3(:icon="['fa', 'phone']")
    | {{ phoneNumber }}
</template>

<script>
export default {
  data () {
    return {
      phoneNumber: '03-5210-0320'
    }
  },
  computed: {
    telLink () {
      return `tel:${this.phoneNumber}`
    }
  }
}
</script>

<style lang='scss' scoped>
.phone-contact-area {
  font-size: 1rem;
}

.phone-icon {
  font-size: 1.6rem;
}

.phone-call-button {
  font-size: 1.4rem;
}
</style>
