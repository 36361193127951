<template lang='pug'>
v-card.primary--text.pa-3.pb-0(height='auto' block outlined :style='styles' @click='onClick')
  v-row(no-gutters align='center')
    v-col(cols=2 align='center')
      v-icon(color='primary' size=32) {{ icon }}
    v-col.pl-6(cols=9 align='left')
      v-card-subtitle.pa-0.primary--text.text-xs-subtitle-1.text-subtitle-2 {{ subtitle }}
      v-card-title.pa-0.primary--text.text-xs-h6.text-subtitle-1 {{ title }}
    v-col(cols=1 align='right')
      v-icon(color='primary') {{ chevronIcon }}
  accordion.mt-4(v-model='isClosed')
    v-row(no-gutters)
      v-col(cols=9)
        v-card.fukidashi.accent(flat)
          v-card-text.pa-2.supplement--text.text-caption {{ text }}
      v-col(cols=3 align-self='end')
        v-img.mt-5(:src='illustrationImage')
</template>

<script>
import Accordion from '@/components/molecules/Accordion'

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    illustrationImage: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isClosed: true
    }
  },
  computed: {
    chevronIcon () {
      return `mdi-chevron-${this.isClosed ? 'down' : 'up'}`
    },
    styles () {
      return {
        borderColor: 'currentColor'
      }
    }
  },
  methods: {
    onClick () {
      this.isClosed = !this.isClosed

      const { isClosed } = this

      this.$emit(isClosed ? 'close' : 'open')
      this.$emit('toggle', this.isClosed)
    }
  },
  components: {
    Accordion
  }
}
</script>

<style scoped>
.fukidashi::after{
    content:'';
    border: solid transparent;
    height:0;
    width:0;
    pointer-events:none;
    position:absolute;
    border-color: transparent;
    border-top-width:12px;
    border-bottom-width: 10px;
    border-left-width: 15px;
    border-right-width: 0;
    margin-left: -10px;
    border-top-color: inherit;
    top:100%;
    left:81%;
}
</style>
