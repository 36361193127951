<template lang="pug">
v-form.simulation-form(ref='form' v-model='valid')
	OutlinedTextField(
		label='年収'
		placeholder='600'
    suffix='万円'
    type="number"
    pattern='\\d*'
		v-model='annualIncome'
		:rules='validationRules.annualIncome'
		required
		:error-messages="errorMessages.annualIncome")

	OutlinedTextField(
		label='本人の年齢'
		placeholder='32'
    suffix='歳'
    type="number"
    pattern='\\d*'
		v-model='age'
		:rules='validationRules.age'
		required
		:error-messages="errorMessages.age")

	OutlinedTextField(
		label='配偶者の年齢'
		placeholder='34'
    suffix='歳'
    type="number"
    pattern='\\d*'
		v-model='partnerAge'
		:rules='validationRules.partnerAge'
		required
		:error-messages="errorMessages.partnerAge")

	OutlinedSelectBox(
		label='18歳以下の子供の人数'
		:items='itemsOfNumberOfChildrenUnder18'
		v-model='selectedChildCountItemValue')

	OutlinedSelectBox(v-for='(_, index) in selectedChildAgeItemValues'
		:key='index'
		:label='generateLabelForAgeOfChildren(index)'
		:items='generateItemsOfChildsAge()'
		v-model='selectedChildAgeItemValues[index]'
		@change='updateChildAges(index, $event)'
		required
		:error-messages="errorMessages.childAges")

	v-row(dense)
		v-col
			v-btn.primary--text.title(@click='reset'
				height='55'
				block
				large
				outlined) 全部クリア
		v-col
			v-btn.primary.title(
				:disabled='!canSubmit'
				height='55'
				depressed
				block
				large
				@click='simulate') 試算する
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import range from '@/utils/range'
import OutlinedTextField from '@/components/atoms/OutlinedTextField'
import OutlinedSelectBox from '@/components/atoms/OutlinedSelectBox'
import Button from '@/components/atoms/Button'
import validator from '@/validator'

const ANNUAL_INCOME_UNIT = 10000

export default {
  components: {
    OutlinedTextField,
    OutlinedSelectBox,
    Button
  },
  data () {
    return {
      valid: false,
      errorMessages: {},
      pending: false,
      validationRules: {
        annualIncome: [],
        age: [],
        partnerAge: []
      },
      itemsOfNumberOfChildrenUnder18: range(0, 4).map(function (value) {
        return {
          label: value + '人',
          value
        }
      })
    }
  },
  computed: {
    ...mapFields([
      'simulationInput.annualIncome',
      'simulationInput.age',
      'simulationInput.partnerAge',
      'simulationInput.selectedChildCountItemValue',
      'simulationInput.selectedChildAgeItemValues',
      'simulationInput.childAges'
    ]),
    canSubmit () {
      return this.valid && this.isFilled && !this.pending
    },
    isFilled () {
      return (!!this.annualIncome && !!this.age && !!this.partnerAge)
    }
  },
  async created () {
    const { annualIncome, age, partnerAge } = await this.$store.dispatch('getBusinessRule', 'simulations/survivor-pension')
    const { validationRules } = this

    validationRules.annualIncome = [
      this.createRangeRule({
        label: '年収',
        min: annualIncome.min / ANNUAL_INCOME_UNIT,
        max: annualIncome.max / ANNUAL_INCOME_UNIT,
        unit: '万円'
      })
    ]
    validationRules.age = [
      this.createRangeRule({
        label: '本人の年齢',
        min: age.min,
        max: age.max,
        unit: '歳'
      })
    ]
    validationRules.partnerAge = [
      this.createRangeRule({
        label: '配偶者の年齢',
        min: partnerAge.min,
        max: partnerAge.max,
        unit: '歳'
      })
    ]

    if (this.isFilled) {
      this.$refs.form.validate()
    }
  },
  watch: {
    selectedChildCountItemValue ({ value }) {
      this.childAges = [...Array(value)].map(() => 0)
      this.selectedChildAgeItemValues = this.childAges.map(this.generateItemsForAgeOfChildren)
    }
  },
  methods: {
    updateChildAges (index, event) {
      const { childAges } = this

      childAges[index] = event.value
      childAges.sort((a, b) => a < b ? 1 : -1)

      this.selectedChildAgeItemValues = childAges.map(this.generateItemsForAgeOfChildren)
    },
    createRangeRule ({ label, min = 0, max = Infinity, unit = '' }) {
      return (v) => {
        return (min <= v && v <= max) || `${label}は${min}-${max}${unit}の範囲で入力して下さい。`
      }
    },
    generateItemsOfChildsAge () {
      const array = range(0, 18).map((value) => ({
        label: `${value}歳`,
        value
      }))

      return array
    },
    getNumberOfChildrenLabel (count) {
      return {
        label: `${count}人`,
        value: count
      }
    },
    generateItemsForAgeOfChildren (age = 0) {
      return {
        label: `${age}歳`,
        value: age
      }
    },
    generateLabelForAgeOfChildren (index) {
      const number = index + 1
      return '第' + number + '子の年齢'
    },
    sortAgeOfChildren () {
      this.ageOfChildren.sort((a, b) => { return a.value < b.value ? 1 : -1 })
    },
    reset () {
      this.$refs.form.reset()

      this.selectedChildCountItemValue = this.getNumberOfChildrenLabel(0)
    },
    async simulate () {
      const data = {
        annualIncome: parseInt(this.annualIncome, 10) * ANNUAL_INCOME_UNIT,
        age: parseInt(this.age, 10),
        partnerAge: parseInt(this.partnerAge, 10),
        childAges: this.childAges
      }

      const errorMessages = validator.simulation(data)
      if (errorMessages) {
        this.errorMessages = errorMessages
        return
      }

      this.pending = true

      try {
        await this.$store.dispatch('postSimulation', data)
        this.$emit('success')
      } catch ({ response }) {
        if (response && response.status === 422) {
          this.errorMessages = this.normalizeErrorMessages(response.data)
        }
        this.$emit('failure')
      } finally {
        this.pending = false
      }
    },
    normalizeErrorMessages (errors) {
      const errorMessages = {}
      errors.forEach((error) => {
        const {
          property
        } = error

        if (Array.isArray(errorMessages[property]) === false) {
          errorMessages[property] = []
        }

        errorMessages[property].push(error.message)
      })
      return errorMessages
    }
  }
}
</script>

<style>
.simulation-form .v-text-field--outlined[required]::after {
	content: "*必須";
  position: absolute;
  top: 2px;
  right: 5px;
  color: #e02020;
  font-size: 12px;
}
</style>
