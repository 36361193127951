<template lang="pug">
v-container.breakdown-area.text-center.accent

  price-bar-chart.mb-4(
    height=240
    :freelancer='freelancer'
    :employee='employee')

  v-card.font-weight-bold.label--text.pa-5.mb-4.headline.white(justify='center' align='center' flat)
    span.primary2--text 約{{ differencePrice | formatJPPriceToTenThousandUnit }}
    span.primary3--text  の差！

  prerequisite.mb-4

  colored-text.title.mb-3.font-weight-bold
    template(#secondary) 月当たりの遺族の受取金額

  div.mb-3 (概算した受取額を期間（月数）で割った金額)

  v-row.mb-4(dense)
    v-col
      type-card(:name='freelancerLabel' color='aqua')
        div.title 約{{ monthlyAmountOfFreelancer | formatJPPriceToTenThousandUnit }}
    v-col
      type-card(name='会社員' color='note')
        div.title 約{{ monthlyAmountOfEmployee | formatJPPriceToTenThousandUnit }}

  colored-text.title.mb-3.font-weight-bold
    template(#secondary) 遺族の生活費・教育費

  div.mb-3 遺族の生活費・教育費を年収580万、配偶者年齢40歳、子供4歳を想定して算出した金額です。これとは別に家賃等の居住費がかかります。

  prerequisite-of-expenses.mb-4

  expense-table.mb-4(
    label='トータル'
    amount='88200000'
    :receivedAmountLabel='freelancerLabel + "の遺族"'
    :receivedAmount='freelancer')

  expense-table(
    label='月額'
    amount='147000'
    receivedAmountLabel='月当たり遺族'
    :receivedAmount='monthlyAmountOfFreelancer')

</template>

<script>
import ColoredText from '@/components/molecules/ColoredText'
import TypeCard from '@/components/molecules/TypeCard'
import Prerequisite from '@/components/organisms/Prerequisite'
import PrerequisiteOfExpenses from '@/components/organisms/PrerequisiteOfExpenses'
import PriceBarChart from '@/components/organisms/PriceBarChart'
import { formatJPPriceToTenThousandUnit } from '@/utils/formatter'
import ExpenseTable from '@/components/molecules/ExpenseTable'
import labels from '@/mixin/labels'

export default {
  mixins: [labels],
  props: {
    freelancer: {
      type: Number,
      required: true
    },
    employee: {
      type: Number,
      required: true
    },
    difference: {
      type: Number,
      required: true
    },
    partnerAge: {
      type: Number,
      required: true
    }
  },
  filters: {
    formatJPPriceToTenThousandUnit (value) {
      return formatJPPriceToTenThousandUnit(value, 10)
    }
  },
  components: {
    ColoredText,
    TypeCard,
    PriceBarChart,
    PrerequisiteOfExpenses,
    ExpenseTable,
    Prerequisite
  },
  computed: {
    differencePrice () {
      return Math.abs(this.difference)
    },
    period () {
      return (90 - this.partnerAge)
    },
    monthlyAmountOfEmployee () {
      return this.calculateMonthlyAmount(this.employee, this.period)
    },
    monthlyAmountOfFreelancer () {
      return this.calculateMonthlyAmount(this.freelancer, this.period)
    }
  },
  methods: {
    calculateMonthlyAmount (annualAmount, period) {
      return annualAmount / (period * 12)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
