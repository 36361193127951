'use strict'

export default {
  methods: {
    movePageInDirectory (name, params) {
      const { dirName } = this.$route.meta

      this.$router.push({
        name: dirName ? `${dirName}-${name}` : name,
        ...params
      })
    },
    async sendLog (eventName) {
      await this.$store.dispatch('postLog', { eventName })
    }
  }
}
