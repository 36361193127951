<template lang="pug">
Content(:isHome='true')
  template(#header)
    colored-text シミュレーション
      template(#secondary) 結果

  break-down-area.mt-5.mb-3(v-bind='survivorPension' :partnerAge='partnerAge')

  v-container.label--text.title.text-center.ma-0 上のグラフの差額は、
    br
    colored-text.headline.font-weight-bold
      template(#secondary) 生命保険で準備可能です！

  v-container.user-action-area
    v-row(dense)
      v-col
        v-dialog(v-model='diagnosisDialog' scrollable persistent flat)
          easy-diagnosis(
            @cancel='hideDiagnosisDialog'
            @success='onSuccess')

        button-with-icon.mb-3.white--text(
          @click='showDiagnosisDialog'
          color='primary2'
          iconSize=36
          height=84
          depressed
          x-large
          icon='icon-search'
          block) おススメほけん診断を始める

  v-row.mt-4.mb-15.pb-10(no-gutters justify='center' align='center')
    a.label--text(@click='movePageInDirectory("home")') 前のページへ戻る
</template>

<script>
import Headline from '@/components/atoms/Headline'
import ColoredText from '@/components/molecules/ColoredText'
import Content from '@/components/organisms/Content'
import BreakDownArea from '@/components/organisms/BreakDownArea'
import ContactForm from '@/components/organisms/ContactForm'
import PhoneContact from '@/components/organisms/PhoneContact'
import ButtonWithIcon from '@/components/molecules/ButtonWithIcon'
import VerticalButtonWithIcon from '@/components/molecules/VerticalButtonWithIcon'
import EasyDiagnosis from '@/components/organisms/EasyDiagnosis'
import page from '@/mixin/page'
import { mapFields } from 'vuex-map-fields'

export default {
  mixins: [page],
  components: {
    Headline,
    BreakDownArea,
    ColoredText,
    Content,
    PhoneContact,
    ButtonWithIcon,
    VerticalButtonWithIcon,
    EasyDiagnosis,
    ContactForm
  },
  data () {
    return {
      diagnosisDialog: false
    }
  },
  computed: {
    ...mapFields([
      'simulationResponse.survivorPension',
      'simulationResponse.partnerAge'
    ])
  },
  methods: {
    onSuccess (id) {
      this.movePageInDirectory(`recommend-${id}`)
    },
    showDiagnosisDialog () {
      this.sendLog('show-easy-diagnosis')
      this.diagnosisDialog = true
    },
    hideDiagnosisDialog () {
      this.sendLog('cancel-easy-diagnosis')
      this.diagnosisDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.difference-area {
  text-align: center;
  font-size: 0.95rem;
}

.detail-area {
  text-align: center;
}

.difference {
  font-size: 1.2rem;
}

.appeal-area {
  font-size: 1.2rem;
}

.difference2 {
  font-size: 1.4rem;
}

.form-title {
  text-align: center;
}
</style>
