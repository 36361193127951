<template lang='pug'>
small.supplementary-explanation.secondary--text
  slot
</template>

<script>
export default {}
</script>

<style scoped>
.supplementary-explanation {
  display: block;
  font-size: 0.84rem;
  line-height: 1.5;
}
</style>
