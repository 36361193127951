<template lang="pug">
v-card.easy-diagnosis(flat tile :loading='isLoading')
  v-toolbar.pa-0(dense flat)
    v-spacer
    v-toolbar-items.pa-0
      v-spacer
      v-btn(icon @click='$emit("cancel")')
        v-icon(size=32) mdi-close
  v-card-title.mb-3.pt-0.primary2--text.font-weight-bold.headline
    v-spacer
    span おススメほけん診断
    v-spacer

  template(v-if='isReady')
    stepper.px-4.mb-3(v-model='currentQuestionIndex'
      :labels='stepperLabels'
      @click-step='moveByIndex')

    v-card-text.pa-0
      v-window.elevation-0(v-model='currentId' touchless)
        v-window-item(v-for='(question, key) in questions'
          transition='slide-x-transition'
          :key='question.id'
          :value='question.id')
          v-card-title.pb-0.default--text {{question.index + 1}}.{{ question.text }}
          v-card-text.pb-0
            v-radio-group(v-model="answers[question.index]" @change='onChangeRadioButton')
              v-radio.mb-3.align-start(v-for="choice, index2 in question.choices"
                :key='index2'
                :value="choice")
                template(#label)
                  span.choice-text.default--text {{ choice.text }}

          v-alert.mx-2(v-show='errorMessage' color='error' tile text dense) {{ errorMessage }}

    v-card-actions
      v-row(dense align='center')
        v-col
          v-btn.white--text.title(v-show='isLastQuestion'
            depressed
            block
            large
            :loading='isLoading'
            :disabled='!isCompleted'
            color='primary2'
            @click='onClickResultButton') 結果へ
          v-btn.white--text.title(v-show='!canClickPrevious'
            block
            depressed large color='primary2'
            :disabled='!canClickNext'
            @click='onClickNextButton') 次の質問へ
        v-col(align='right')
          v-btn.white--text.title(v-show='canClickPrevious'
            outlined
            block
            large
            color='primary2'
            @click='onClickPreviousButton') {{ currentQuestionIndex }}問目に戻る

  template(v-else)
    v-card-text(align='center')
      v-progress-circular.mb-5(
        size="36"
        color="primary2"
        indeterminate)
</template>

<script>
'use strict'
import Stepper from '@/components/molecules/Stepper'
import page from '@/mixin/page'
import _ from 'lodash'

export default {
  components: {
    Stepper
  },
  mixins: [page],
  methods: {
    async sendClickLog (buttonName) {
      await this.sendLog(`click-${buttonName}-button-in-easy-diagnosis`)
    },
    sendGTMLog (value) {
      if (this.$gtm) {
        this.$gtm.trackEvent({
          eventCategory: 'osusume-hoken-shindan',
          ...value
        })
      }
    },
    onClickPreviousButton () {
      const previousIndex = (this.currentQuestionIndex - 1)

      if (previousIndex >= 0) {
        this.sendGTMLog({
          eventAction: 'click-previous'
        })
        this.sendClickLog('previous')
        this.moveByIndex(previousIndex)
      }
    },
    onClickNextButton () {
      const { next } = this.answers[this.currentQuestionIndex]

      if (next) {
        this.sendGTMLog({
          eventAction: 'click-next'
        })
        this.sendClickLog('next')
        this.currentId = next
      }
    },
    async onClickResultButton () {
      this.isSubmitting = true
      this.errorMessage = null

      this.sendGTMLog({
        eventAction: 'click-result'
      })
      await this.sendClickLog('result')

      try {
        const { product } = await this.$store.dispatch('postDiagnoses', {
          answers: this.answers
        })
        this.sendGTMLog({
          eventAction: `get-result-${product.id}`,
          eventLabel: product.name
        })
        this.$emit('success', product.id)
      } catch (error) {
        this.errorMessage = error.response.data.message
      } finally {
        this.isSubmitting = false
      }
    },
    async onChangeRadioButton ({ text, value, next }) {
      this.sendGTMLog({
        eventAction: `select-${value}`,
        eventLabel: text
      })
      this.sendLog(`select-${value}-in-easy-diagnosis`)

      if (next) {
        const { currentQuestionIndex } = this
        this.answers = this.answers.map((value, index) => (currentQuestionIndex < index) ? null : value)
      }
    },
    moveByIndex (index) {
      if ((index > (this.questionCount - 1)) ||
        (index === this.currentQuestionIndex)) {
        return
      }

      const filtered = _.values(_.pickBy(this.questions, (value) => value.index === index))
      const question = filtered.length > 1 ? this.getOneFromSameIndexQuestions(filtered) : filtered[0]

      if (question) {
        this.currentId = question.id
      }
    },
    getOneFromSameIndexQuestions (questions = []) {
      const { index } = questions[0]
      const previousAnswer = this.answers[index - 1]

      if (!previousAnswer) {
        return null
      }

      return questions.find(({ id }) => id === previousAnswer.next)
    }
  },
  async created () {
    const data = await this.$store.dispatch('getQuestions')
    const { questionCount } = data

    this.id = data.id
    this.currentId = data.firstQuestion
    this.questionCount = questionCount
    this.questions = data.questions
    this.answers = [...Array(questionCount)].map(() => null)
    this.isReady = true
  },
  computed: {
    isLoading () {
      return this.isReady === false || this.isSubmitting === true
    },
    isCompleted () {
      return !!this.currentAnswer && this.isLastQuestion && this.canClickNext
    },
    isLastQuestion () {
      return this.currentQuestion.isLast === true
    },
    canClickPrevious () {
      return this.currentQuestionIndex > 0
    },
    canClickNext () {
      return !!this.answers[this.currentQuestionIndex]
    },
    currentAnswer () {
      return this.answers[this.currentQuestionIndex]
    },
    currentQuestion () {
      return this.questions[this.currentId]
    },
    currentQuestionIndex () {
      return this.currentQuestion.index
    },
    stepperLabels () {
      const labels = [...Array(this.questionCount)]
        .map((item, index) => `${index + 1}問`)

      // Last label
      labels.push('結果')

      return labels
    }
  },
  data () {
    return {
      isReady: false,
      isSubmitting: false,
      errorMessage: null,
      id: null,
      currentId: null,
      questionCount: 0,
      answers: [],
      questions: {}
    }
  }
}
</script>

<style scoped>
.choice-text {
  line-height: 1.63 !important;
}
</style>
