import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'
import createPersistedState from 'vuex-persistedstate'
import { STORAGE_KEY } from '@/constants'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    trackingId: null,
    sex: 1,
    simulationInput: {
      annualIncome: null,
      age: null,
      partnerAge: null,
      childAges: [],
      selectedChildAgeItemValues: [],
      selectedChildCountItemValue: {
        label: '0人',
        value: 0
      }
    },
    isSimulated: false,
    simulationResponse: {
      survivorPension: {
        freelancer: 0,
        employee: 0,
        difference: 0
      },
      receiveType: 0,
      premiumPerMonth: 0,
      insurancePrice: 0,
      contractAge: 0,
      premiumPaymentPeriod: 0
    },
    contactInput: {
      userName: '',
      email: '',
      comment: ''
    },
    contactResponse: null
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    UPDATE_TRACKING_ID (state, trackingId) {
      state.trackingId = trackingId
    },
    UPDATE_SIMULATION_INPUT (state, simulationInput) {
      state.simulationInput = simulationInput
    },
    UPDATE_SIMULATION_RESPONSE (state, simulationResponse) {
      state.isSimulated = true
      state.simulationResponse = simulationResponse
    },
    DELETE_SIMULATION_INPUT (state) {
      state.isSimulated = false
      state.simulationInput = {}
    },
    UPDATE_CONTACT_INPUT (state, contactInput) {
      state.contactInput = Object.assign({}, state.contactInput, contactInput)
    },
    UPDATE_CONTACT_RESPONSE (state) {
      state.contactResponse = {}
    },
    DELETE_CONTACT_INPUT (state) {
      state.contactInput = {}
    }
  },
  actions: {
    async postSimulation ({ state, commit }, data) {
      const { data: result } = await api.simulation({
        ...data,
        url: document.URL,
        trackingId: state.trackingId
      })
      commit('UPDATE_SIMULATION_RESPONSE', result)
      commit('UPDATE_TRACKING_ID', result.trackingId)
    },
    async postContact ({ state, commit }, data) {
      // state.contactFailure = false
      const { data: result } = await api.contact({
        ...data,
        type: 'survivor-pension',
        url: document.URL,
        trackingId: state.trackingId
      })
      commit('UPDATE_CONTACT_RESPONSE', result)
      commit('UPDATE_TRACKING_ID', result.trackingId)
    },
    async postLog ({ commit, state }, data) {
      const { data: result } = await api.log({
        ...data,
        url: document.URL,
        trackingId: state.trackingId
      })
      commit('UPDATE_TRACKING_ID', result.trackingId)
    },
    async getBusinessRule ({ commit }, name) {
      const { data } = await api.businessRule(name)

      return data
    },
    async calcInsurancePremium ({ commit }, data) {
      const { data: result } = await api.calculation(data)
      return result
    },
    async getQuestions ({ commit }) {
      const { data } = await api.question()

      return data
    },
    async postDiagnoses ({ state, commit }, data) {
      const { data: result } = await api.diagnosis({
        ...data,
        id: 'osusume-hoken-shindan',
        url: document.URL,
        trackingId: state.trackingId
      })
      commit('UPDATE_TRACKING_ID', result.trackingId)

      return result
    }
  },
  plugins: [createPersistedState({
    key: STORAGE_KEY,
    storage: window.localStorage
  })]
})

export default store
