<template lang='pug'>
v-btn.vertical-button-with-icon.ma-0.pa-6(v-bind='$attrs'
  v-on='$listeners'
  outlined
  large
  color='primary2'
  tag='a'
  align='center'
  width='100%'
  height='100%')
  v-container.pa-0(fill-height fluid)
    v-row(justify='center' no-gutters)
      div.icon-image.mb-3(width='40px')
        font-awesome-icon(v-if='isFontAwesomeIcon' :icon='icon')
        v-icon(v-else) {{ icon }}
    v-row.pa-0(no-gutters justify='center' align='center')
      slot
</template>

<script>
import buttonWithIcon from '@/mixin/button-with-icon'

export default {
  mixins: [buttonWithIcon]
}
</script>

<style lang='scss' scoped>
.vertical-button-with-icon {
  font-size: 1.1rem !important;
}

.icon-image {
  color: inherit;
}

.icon-image i {
  font-size: 3rem;
  color: inherit;
}

.button-with-icon {
  font-size: 1.3rem;
}
</style>
