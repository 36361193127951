<template lang="pug">
annotation(:title='title')
  colored-text.prerequisite-title.mb-3 シミュレーションの主な前提条件

  ul.detail-list.mb-3
    li 令和２年１２月の制度に基づき計算
    li 本人を男性、配偶者を女性とする
    li 本人、配偶者ともに保険料納付要件を満たしている
    li 本人は現在年齢で死亡
    li 配偶者は90歳で死亡
    li 配偶者は65歳から老齢基礎年金を受給
    li 配偶者は再婚しない
    li 配偶者は厚生年金非加入
    li 婚姻関係は10年未満
    li 子供は未婚で、障害の状態にない
    li {{ freelancerLabel }}は厚生年金加入歴なし
    li 遺族厚生年金算出におけるサラリーマンの報酬は過去にさかのぼり現在と同額、被保険者期間は300月、係数は全期間に5.481を適用
    li 経過的寡婦加算は考慮しない

  span.annotation ※あくまで概算把握のためですので、詳細なシミュレーションや正確な金額を把握されたい場合は、専門家等へご相談ください。
</template>

<script>
import Annotation from '@/components/molecules/Annotation'
import ColoredText from '@/components/molecules/ColoredText'
import labels from '@/mixin/labels'

export default {
  mixins: [labels],
  components: {
    Annotation,
    ColoredText
  },
  props: {
    title: {
      type: String,
      default: 'シミュレーションの主な前提条件を読む'
    }
  }
}
</script>

<style lang="scss" scoped>
.prerequisite-title {
  font-size: 1rem;
}

.detail-list {
  font-size: 1rem;
  line-height: 1.6;
}
</style>
