<template lang="pug">
div#header
  Headline.text-center(v-if='this.$slots.header')
    slot(name='header')
  Description.px-4.text-left(v-if='this.$slots.description')
    slot(name='description')
</template>

<script>
import Headline from '../atoms/Headline'
import Description from '../atoms/Description'

export default {
  components: {
    Headline,
    Description
  }
}
</script>
