<template lang='pug'>
Content(showFooter=true)
  template(#header) Error
  template(#description) Not found
</template>

<script>
import Content from '@/components/organisms/Content'

export default {
  components: {
    Content
  }
}
</script>
