<template lang='pug'>
v-container.check-list
  v-row(v-if='title' dense)
    v-col.title(align='center') {{ title }}
  v-row.mt-3.title(v-for='(item, index) in value' :key='index' dense)
    v-col.col-2
      v-icon.primary2--text(x-large) {{ icon }}
    v-col
      div {{ item.text || item }}
      div.subtitle-1.mt-1.secondary--text(v-if='item.supplement') {{ item.supplement }}
      a.subtitle-1.mt-1.default--text(v-if='item.link' :href='item.link.href' target='_blank') {{ item.link.text }}
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      default: 'circle'
    },
    value: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>
