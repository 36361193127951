'use strict'

import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import router from '@/router/'

const { VUE_APP_GOOGLE_TAG_MANAGER_ID } = process.env

if (VUE_APP_GOOGLE_TAG_MANAGER_ID) {
  Vue.use(VueGtm, {
    id: VUE_APP_GOOGLE_TAG_MANAGER_ID,
    vueRouter: router
  })
}
