<template lang="pug">
div#content
  Header
    template(#header)
      slot(name='header')
    template(#description)
      slot(name='description')
  slot
  Footer.mb-4(v-if='showFooter')
</template>

<script>
import Header from '@/components/molecules/Header'
import Footer from '@/components/organisms/Footer'

export default {
  components: {
    Header,
    Footer
  },
  props: {
    showFooter: {
      type: Boolean,
      default: false
    }
  }
}
</script>
