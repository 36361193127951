<template lang="pug">
AccordionListItem.mb-4(v-on='$listeners')
  template(#title)
    div.title 支出について詳しくはこちら
  v-container.title.pt-0
    p.subtitle-1.mb-2 以下のように仮定して計算してみましょう。

    v-card.mb-3(v-for='items, index in expenditureItems' :key='"card-" + index' outlined)
      v-list(two-line dense)
        template(v-for='item, index2 in items.items')
          v-list-item(:key='"list-" + index2')
            v-list-item-content.pa-0
              div.mb-1.subtitle-1 {{ item.title }} :
              div.title(:class='getListTextClass(items.color)') {{ item.text }}
                span.subtitle-1.annotation(v-if='items.annotation')
                  sup *
                  | {{ items.annotation }}
          v-divider(v-if='hasDivider(index2, items.items)')

    p.subtitle-1.mb-0
      | *1「文部科学省令」、文部科学省「私立大学等の平成30年度入学者に係る学生納付金等調査結果について」「平成30年度子供の学習費調査」をもとにエヌエヌ生命にて算出
      br
      | *2 鎌倉新書「第4回お葬式に関する全国調査」(2020年)
</template>

<script>
import AccordionListItem from '@/components/molecules/AccordionListItem'

export default {
  components: {
    AccordionListItem
  },
  methods: {
    hasDivider (index, { length }) {
      return index < (length - 1)
    },
    getListTextClass (color) {
      return {
        [`${color}--text`]: true
      }
    }
  },
  data () {
    return {
      expenditureItems: [
        {
          color: 'aqua',
          items: [
            {
              title: '末子独立までの生活費',
              text: '現在の生活費の70%程度'
            },
            {
              title: '末子独立後の生活費',
              text: '現在の生活費の50%程度'
            }
          ]
        },
        {
          color: 'note',
          annotation: 1,
          items: [
            {
              title: 'お子さまの教育費(幼稚園から大学まで公立)',
              text: '約790万円'
            },
            {
              title: 'お子さまの教育費(幼稚園から大学まで私立)',
              text: '約2,290万円'
            }
          ]
        },
        {
          color: 'note',
          annotation: 2,
          items: [
            {
              title: '葬儀費用(葬儀費用、飲食費、返礼品の合計)',
              text: '約180万円'
            },
            {
              title: 'お墓の購入が必要であればさらに',
              text: '約140万円'
            }
          ]
        }
      ]
    }
  }
}
</script>
