<template lang='pug'>
div.annotation
  a.annotation-link(@click.stop='showDialog')
    span.annotation-link-text.label--text {{ title }}
  v-dialog(v-model="dialog" max-width="80%")
    v-card.dialog-content.py-5.px-4
      v-card-text.pa-0.mb-3
        slot
      v-layout.dialog-footer.justify-end
        a.close-link(@click.stop='hideDialog') 閉じる
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      dialog: false
    }
  },
  methods: {
    showDialog: function () {
      this.dialog = true
    },
    hideDialog: function () {
      this.dialog = false
    }
  }
}
</script>

<style lang='scss' scoped>
.annotation-link {
  font-size: 1rem;
  text-decoration: none;
}

.annotation-link-text {
  text-decoration: underline;
}

.annotation-link:hover {

  .close-link, .annotation-link-text {
    text-decoration: none;
  }
}

.dialog-content {
  font-size: 1rem;
  line-height: 1.8;
}

.close-link {
  display: inline-block;
}
</style>
