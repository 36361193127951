<template lang='pug'>
Content.contact-page(showFooter=true)
  template(#header)
    colored-text 保険
      template(#secondary) 相談
  template(#description)
    p 保険の詳細は担当者よりご案内させていただきます。以下のいずれかでお問い合わせをお願いします。
    privacy-policy-link.secondary--text.mb-3 エヌエヌ生命の個人情報保護方針はこちらからご確認ください

  v-container.mt-5.mb-4.accent
    contact-buttons(pageName='contact')
</template>

<script>
import ButtonWithIcon from '@/components/molecules/ButtonWithIcon'
import ContactButtons from '@/components/organisms/ContactButtons'
import ColoredText from '@/components/molecules/ColoredText'
import PrivacyPolicyLink from '@/components/molecules/PrivacyPolicyLink'
import Content from '@/components/organisms/Content'
import ApplicationArea from '@/components/organisms/ApplicationArea'
import page from '@/mixin/page'

export default {
  mixins: [page],
  components: {
    ButtonWithIcon,
    ContactButtons,
    Content,
    ColoredText,
    PrivacyPolicyLink,
    ApplicationArea
  }
}
</script>
