<template lang="pug">
v-snackbar#reservation-button-navigation(
  v-bind='$attrs'
  v-on='$listeners'
  color='white'
  timeout='-1'
  tile
  outlined
  bottom
  v-model='snackbar'
  transition='slide-y-reverse-transition')
  v-row(dense)
    v-col
      button-with-icon.reservation-button.white--text.title(
          :href='reservationFormUrl'
          target='_blank'
          depressed
          block
          icon='icon-laptop'
          height='64'
          icon-size='40'
          color='primary2') オンライン保険相談予約
  v-row(dense)
    v-col.default--text.title(align='center') オンライン相談受付中！
        br
        | ご希望の日時でご予約ください
</template>

<script>
import ButtonWithIcon from '@/components/molecules/ButtonWithIcon'

export default {
  components: {
    ButtonWithIcon
  },
  data () {
    return {
      snackbar: true,
      reservationFormUrl: `${process.env.VUE_APP_PREMIUM_ESTIMATION_URL}/reservation`
    }
  }
}
</script>

<style>
#reservation-button-navigation {
  top: auto;
}

#reservation-button-navigation .v-snack__wrapper {
  min-width: 100%;
  margin: 0;
}
</style>
