<template lang='pug'>
v-row.pa-0(justify='center' no-gutters)
  v-card.white--text.pa-1.supplement.subtitle-1(
    v-if='supplement'
    width='80%'
    :class='color'
    flat) {{ supplement }}
  v-card.py-2.px-4.white.title(:class='textClass' flat)
    v-row( align='center')
      v-col.col-3
        v-icon.mx-2(:class='textClass' size='36') icon-money
      v-col.col-9.subtitle-1 {{ name }}
        br
        |
        span.headline {{ amount | formatJPUnitPrice }}
</template>

<script>
import { formatJPUnitPrice } from '@/utils/formatter'

export default {
  props: {
    name: {
      tyep: String,
      required: true
    },
    amount: {
      type: Number,
      default: 0
    },
    supplement: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  filters: {
    formatJPUnitPrice
  },
  computed: {
    textClass () {
      return `${this.color}--text`
    }
  }
}
</script>

<style scoped>
.supplement {
  transform: translateY(0.5rem);
  z-index: 1;
}
</style>
