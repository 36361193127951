<template lang='pug'>
v-container.example.pa-0
  v-container.accent
    contract-example-card.white(
      v-bind='examples[0]'
      :product='product'
      :termKindCode='termKindCodeForExampleCard'
      :period='periodForExampleCard'
      :expirationAge='period'
      :age='age'
      ref='exampleCard')

  v-container.mb-5(v-if='!hideTable')
    accordion-list-item(@toggle='sendLog("toggle-contract-example-accordion")' title='他の条件での保険料を見る')
      v-container
        v-simple-table.text-center.mb-3(style='white-space: nowrap;')
          thead
            tr.table-header
              th.text-center.title {{ firstHeaderLabel }}
              th.text-center.title(v-for='period in periods' :key='period') {{ period }}{{ periodLabel }}
          tbody
            tr(v-for='items, key in tableItems' :key='key')
              td.title {{ key | formatJPPriceToTenThousandUnit }}
              td.py-2.title(v-for='item in items')
                template(v-if='item.success')
                  template(v-if='isSitp')
                    div.primary2--text {{ calculateMonthlyAmount(item.estimatedAmount) | formatJPPrice }}/月
                    div.default--text {{ item.estimatedAmount | formatJPPrice }}/年
                  template(v-else)
                    div.primary2--text {{ item.estimatedAmount | formatJPPrice }}/月
                div.default--text.no-value(v-else) -
        div 保険料払込方法：{{ paymentRouteLabel }}

</template>

<script>
'use strict'

import AccordionListItem from '@/components/molecules/AccordionListItem'
import ContractExampleCard from '@/components/organisms/ContractExampleCard'
import { mapFields } from 'vuex-map-fields'
import { formatJPPrice, formatJPPriceToTenThousandUnit } from '@/utils/formatter'
import page from '@/mixin/page'
import example from '@/mixin/example'
import _ from 'lodash'

export default {
  props: {
    product: {
      type: String,
      required: true
    },
    termKindCode: {
      type: [String, Number],
      required: true
    },
    period: {
      type: [String, Number],
      required: true
    },
    examples: {
      type: Array,
      required: true
    }
  },
  mixins: [page, example],
  filters: {
    formatJPPrice,
    formatJPPriceToTenThousandUnit
  },
  mounted () {
    this.updateStatus()
  },
  methods: {
    calculateMonthlyAmount (value) {
      return value / 12
    },
    async updateStatus () {
      if (this.hideTable === true) {
        return
      }

      this.isLoading = true
      try {
        const { result } = await this.$store.dispatch('calcInsurancePremium', {
          product: this.product,
          inputs: this.inputData
        })

        this.calculatedData = result
      } catch (error) {
        this.errorMessage = error.response.data.message
      } finally {
        this.isLoading = false
      }
    }
  },
  data () {
    return {
      periodCount: 4,
      periodUnit: 5,
      calculatedData: [],
      isLoading: false
    }
  },
  watch: {
    sex () {
      this.updateStatus()
    }
  },
  computed: {
    ...mapFields(['simulationInput.age', 'sex']),
    hideTable () {
      return (this.shouldUseExpirationAge && (this.isOverMaxAge === true || this.isBelowMinAge === true))
    },
    termKindCodeForExampleCard () {
      return this.hideTable ? 1 : this.termKindCode
    },
    periodForExampleCard () {
      return this.shouldUseExpirationAge ? this.periodByExpirationAge : this.period
    },
    paymentRouteLabel () {
      return this.isSitp ? '年払' : '月払'
    },
    firstHeaderLabel () {
      return this.isSitp ? '基準保険金額' : '年金月額'
    },
    periodLabel () {
      return this.shouldUseExpirationAge ? '歳' : '年'
    },
    minPeriod () {
      return this.maxPeriod - (this.periodCount * this.periodUnit)
    },
    maxPeriod () {
      return this.shouldUseExpirationAge ? this.period : 20
    },
    periods () {
      const {
        minPeriod,
        periodUnit,
        age
      } = this

      const periods = [...Array(this.periodCount)].map((_, i) => ((i + 1) * periodUnit) + minPeriod)

      return this.termKindCode === 1
        ? periods
        : periods.filter((period) => period >= age)
    },
    inputData () {
      const {
        sex,
        age,
        periods,
        shouldUseExpirationAge
      } = this

      let inputs = []

      for (const period of periods) {
        const termInsured = (shouldUseExpirationAge === true) ? (period - age) : period
        const generated = this.examples.map(({ baseAmount, monthlyPensionAmount }) => {
          return {
            termInsured,
            baseAmount,
            monthlyPensionAmount,
            age,
            sex
          }
        })

        inputs = inputs.concat(generated)
      }

      return inputs
    },
    tableItems () {
      return _.groupBy(this.calculatedData, 'inputItems.' + (this.isSitp ? 'baseAmount' : 'monthlyPensionAmount'))
    }
  },
  components: {
    AccordionListItem,
    ContractExampleCard
  }
}
</script>
