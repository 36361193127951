<template lang='pug'>
Dialog(v-bind='$attrs' v-on='$listeners')
  v-card-title.dialog-title.primary--text.pa-0.mb-3(v-if='!!title') {{ title }}
  v-card-text.pa-0.mb-3
    slot
  v-layout.dialog-footer.justify-end
    a.close-link(@click.stop='hideDialog') 閉じる
</template>

<script>
import Dialog from '@/components/atoms/Dialog'

export default {
  components: {
    Dialog
  },
  props: {
    title: {
      type: String,
      default: null
    }
  },
  methods: {
    hideDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
.dialog-content {
  line-height: 1.8;
}

.dialog-title {
  font-size: 15px;
  font-weight: 600;
}

.close-link {
  display: inline-block;
}
</style>
