<template lang="pug">
div.description.default--text
  slot
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.description {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-stretch: normal;
  letter-spacing: 0.8px;
}
</style>
