<template lang="pug">
v-container.difference-area.pa-0
  v-container.px-5.py-0
    Headline.text-center.mt-6.mb-6 1. 30代・40代の加入額は大きい！
    v-img.mb-5(:src='graphSrc')
    div.label--text.text-left.subtitle-1
      | 死亡保険で準備しておきたい金額は年齢や家族構成によって異なります。他の年代と比べて30代・40代の加入額が大きいのは、まだ幼い子供がいるなど
      span.primary2--text 「遺族が生きていくために必要な支出」
      | を多く見込んでいるからと言えるでしょう。

  Headline.text-center.mt-8.mb-6 ２. {{ freelancerLabel }}の加入額は大きい！
  v-container.accent.pt-3
    v-row(align='end' dense)
      DeathBenefitCard(v-bind='deathBenefitItem')
  v-container.px-5.label--text.text-left.subtitle-1
    p 死亡保険で準備しておきたい金額は就業形態によっても異なります。
      |
      span.primary2--text 「遺族に今後見込まれる収入」
      | の一つである遺族年金の額が会社員よりもずっと少ない{{ freelancerLabel }}は、死亡保険に多く加入して、家族に遺せるお金を確保しているようです。
    span.secondary--text
      | 【出典】（公財）生命保険文化センター「平成30年度生命保険に関する全国実態調査」
</template>

<script>
import Headline from '@/components/atoms/Headline'
import DeathBenefitCard from '@/components/molecules/DeathBenefitCard'
import { formatJPUnitPrice } from '@/utils/formatter'
import labels from '@/mixin/labels'

export default {
  mixins: [labels],
  components: {
    Headline,
    DeathBenefitCard
  },
  data () {
    return {
      graphSrc: require('@/assets/lifestyle_graph.svg'),
      deathBenefitOfEmployee: 1935 * 10000,
      deathBenefitOfFreelancer: 2385 * 10000,
      deferenceAmount: 400 * 10000
    }
  },
  filters: {
    formatJPUnitPrice
  },
  computed: {
    deathBenefitItem () {
      return {
        name: this.freelancerLabel,
        amount: this.deathBenefitOfFreelancer,
        color: 'note'
      }
    }
  }
}
</script>
