<template lang='pug'>
div.colored-text.primary--text
  slot
  span.primary2--text(v-if='this.$slots.secondary')
    slot(name='secondary')
  span.primary3--text(v-if='this.$slots.tertiary')
    slot(name='tertiary')
</template>

<script>
export default {}
</script>
