'use strict'

import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/Home'
import Result from '@/pages/Result'
import Thanks from '@/pages/Thanks'
import Recommend from '@/pages/Recommend'
import Contact from '@/pages/Contact'
import NotFound from '@/pages/NotFound'
import Information from '@/pages/Information'
import Preparation from '@/pages/Preparation'
import EmergencyPlus from '@/pages/EmergencyPlus'
import { B_PATTERN_DIR_NAME } from '@/constants'
import store from '@/store'
import RECOMMEND_META_DICTIONARY from './recommend-meta-dictionary'
import _ from 'lodash'

Vue.use(Router)

const validateIfUserSimulated = (to, from, next) => {
  if (store.state.isSimulated === true) {
    next()
  } else {
    next({ name: 'home' })
  }
}

const copyRoutes = (names = [], originalRoutes = []) => names.map((dirName) => {
  return originalRoutes.map((route) => {
    const copied = _.cloneDeep(route)
    const { path } = copied

    copied.name = `${dirName}-${copied.name}`
    copied.path = `/${dirName}${path}`

    if (!copied.meta) {
      copied.meta = {}
    }

    const { meta } = copied
    meta.dirName = dirName

    if (meta.previousPage) {
      meta.previousPage = `${dirName}-${meta.previousPage}`
    }

    return copied
  })
}).flat()

const ROUTES = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/result',
    name: 'result',
    component: Result,
    beforeEnter: validateIfUserSimulated,
    meta: {
      previousPage: 'home'
    }
  },
  ..._.keys(RECOMMEND_META_DICTIONARY).map((key) => {
    return {
      path: `/recommend/${key}`,
      name: `recommend-${key}`,
      beforeEnter: validateIfUserSimulated,
      component: /^sitp/.test(key) ? EmergencyPlus : Recommend,
      meta: {
        hideChat: true,
        ...RECOMMEND_META_DICTIONARY[key]
      }
    }
  }),
  {
    path: '/information',
    name: 'information',
    component: Information,
    beforeEnter: validateIfUserSimulated,
    meta: {
      previousPage: 'result'
    }
  },
  {
    path: '/preparation',
    name: 'preparation',
    component: Preparation,
    beforeEnter: validateIfUserSimulated,
    meta: {
      previousPage: 'result'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    beforeEnter: validateIfUserSimulated,
    meta: {
      previousPage: 'result'
    }
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: Thanks,
    beforeEnter: validateIfUserSimulated,
    meta: {
      previousPage: 'contact'
    }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    ...ROUTES,
    ...copyRoutes([B_PATTERN_DIR_NAME], ROUTES),
    {
      path: '/*',
      name: 'error',
      component: NotFound,
      meta: {
        previousPage: 'home'
      }
    }]
})

router.afterEach((to, from) => {
  store.dispatch('postLog', { eventName: `open-${to.name}` })
})

const getChatElement = () => document.querySelector('.embeddedServiceHelpButton')
const hideChatIfChatElementExists = (to, from) => {
  // Hide chat button

  if (to.meta.hideChat === true) {
    const chatButtonElement = getChatElement()

    if (chatButtonElement === null) {
      // Retry
      setTimeout(() => hideChatIfChatElementExists(to, from), 100)
    } else {
      chatButtonElement.style.display = 'none'
    }
  }
}

router.afterEach(hideChatIfChatElementExists)

router.afterEach((to, from) => {
  if (from.meta.hideChat === true) {
    const chatButtonElement = getChatElement()

    if (chatButtonElement !== null) {
      chatButtonElement.style.display = 'block'
    }
  }
})

const updateUserInsight = (to) => {
  // For User insight app
  const _uih = window._uih
  const _uic = window._uic

  if ((typeof _uih === 'undefined') ||
    (typeof _uic === 'undefined') ||
    (typeof _uic.send_pv === 'undefined')) {
    // Retry until User Insight's script has been loaded.
    return setTimeout(updateUserInsight.bind(null, to), 500)
  }

  _uih.url = `${location.origin}${to.path}`
  _uih.sb = document.title
  _uih.ref = document.referrer

  _uic.send_pv(_uih, _uic)
}

router.afterEach(updateUserInsight)

export default router
