<template lang='pug'>
v-card.transparent(flat)
  v-row(dense)
    v-col(align='center')
      v-card.pa-0.rounded-circle(
        flat
        justify='center'
        color='white'
        width='100'
        height='100')
          v-card.rounded-0.transparent(
            flat
            width='64' height='100%')
            v-row.fill-height(align='center' no-gutters)
              v-col(align='center')
                v-img(:src='image' contain)
  v-row(dense)
    v-col.primary2--text.title(align='center')
      slot(name='text')
  v-row(no-gutters)
    v-col.title(align='center')
      slot(name='price')
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    }
  }
}
</script>
