<template lang="pug">
ButtonWithIcon.mail-call-button.aqua.background--text(v-bind='$attrs'
  v-on='$listeners'
  depressed
  :icon='icon'
  :href='mailLink')
  | メールで無料相談
</template>

<script>
import { CONTACT_EMAIL_ADDRESS } from '@/constants'
import ButtonWithIcon from '@/components/molecules/ButtonWithIcon'

export default {
  components: {
    ButtonWithIcon
  },
  data () {
    return {
      address: CONTACT_EMAIL_ADDRESS,
      icon: 'icon-mail'
    }
  },
  computed: {
    mailLink () {
      return `mailto:${this.address}`
    }
  }
}
</script>
