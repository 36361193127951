<template lang="pug">
v-row.mb-16(no-gutters justify='center' align='center')
  a.secondary--text(@click.prevent='onClickReturnLink') 前のページへ戻る
</template>

<script>
import page from '@/mixin/page'

export default {
  mixins: [page],
  methods: {
    onClickReturnLink () {
      const { meta, params } = this.$route

      if (params.isReturning === true) {
        this.movePageInDirectory(meta.previousPage, {
          params: {
            isReturning: true
          }
        })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>
