<template lang='pug'>
v-chip(color='primary2'
  outlined
  label
  v-bind='$attrs'
  v-on='$listeners')
  slot
</template>

<script>
export default {
}
</script>
