'use strict'

import { B_PATTERN_DIR_NAME } from '@/constants'

export default {
  computed: {
    isInBDirectory () {
      return (this.$route.meta.dirName === B_PATTERN_DIR_NAME)
    },
    freelancerLabel () {
      return this.isInBDirectory ? '個人事業主' : 'フリーランス'
    }
  }
}
