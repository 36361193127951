<template lang="pug">
v-list.pa-0.accordion-list-item(dense)
  div.graph-area-wrapper(:class='graphAreaClasses' :style='graphAreaStyles')
    v-list-item.px-2(@click='onClickViewDetailButton')
      v-list-item-icon.mr-3
        v-icon.graph-area-icon.primary2--text(size=32) mdi-chevron-down
      v-list-item-content
        v-list-item-title.subtitle-1.list-title-text(:class='graphAreaHeaderClasses')
          slot(name='title')
            span.title {{ title }}
    Accordion(v-model='isClosed')
      slot
</template>

<script>
import Accordion from '@/components/molecules/Accordion'

export default {
  props: {
    title: {
      type: String,
      default: null
    }
  },
  components: {
    Accordion
  },
  data () {
    return {
      isClosed: true
    }
  },
  computed: {
    graphAreaClasses () {
      const { isClosed } = this

      return {
        'is-opened': !isClosed,
        'is-closed': isClosed,
        'border-top': isClosed,
        'border-bottom': isClosed,
        accent: !isClosed
      }
    },
    graphAreaStyles () {
      const { light } = this.$vuetify.theme.themes

      return this.isClosed
        ? {}
        : {
            'border-top': `2px solid ${light.primary2} !important`
          }
    },
    graphAreaHeaderClasses () {
      return (this.isClosed ? 'default' : 'primary2') + '--text'
    }
  },
  methods: {
    onClickViewDetailButton () {
      this.isClosed = !this.isClosed

      if (this.isClosed === true) {
        this.$emit('close')
      } else {
        this.$emit('open')
      }

      this.$emit('toggle', { isClosed: this.isClosed })
    }
  }
}
</script>

<style scoped>
.supplement-text {
  white-space: pre-wrap;
}

.graph-area-icon {
  transition: 0.25s;
  transform: rotate(0deg);
  transform-origin:center center;
}

.is-opened .graph-area-icon {
  transform: rotate(180deg);
}

.v-list-item__title {
  overflow: visible;
}
</style>
