<template lang="pug">
Content(showFooter=true)
  template(#header)
    colored-text
      template(#secondary) 死亡保険の加入金額、平均はいくら？
  template(#description)
    p.label--text 世帯主が死亡した際に支払われる生命保険の平均額は1,406万円。遺族年金だけでは遺族の生活を守れないと考え、死亡保険にもしっかりと加入しています。

  v-container.text-center.pa-0.mb-6
    difference.mb-5

    v-row.px-5(no-gutters)
      v-col.mb-3
        v-btn.primary2.title.white--text(
          outlined
          block
          min-height='80'
          @click='movePageInDirectory("recommend")')
          v-icon(size='40px').mr-4 icon-star
          span.btn-text {{ freelancerLabel }}におすすめの保険！
    v-row.px-5(no-gutters)
      v-col
        v-btn.primary2.title.white--text(
          outlined
          block
          min-height='80'
          @click='movePageInDirectory("contact")')
          v-icon(size='40px').mr-4 icon-bubble
          | 保険相談する
</template>

<script>
import ColoredText from '@/components/molecules/ColoredText'
import Content from '@/components/organisms/Content'
import Difference from '@/components/organisms/Difference'
import page from '@/mixin/page'
import labels from '@/mixin/labels'

export default {
  mixins: [page, labels],
  components: {
    Difference,
    ColoredText,
    Content
  }
}
</script>

<style scoped>
span.btn-text {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
