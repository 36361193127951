<template lang="pug">
h1.headline-text.primary2--text.text-xs-center.mb-3
  slot
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.headline-text {
  font-size: 1.3rem;
  font-weight: 600;
}
</style>
