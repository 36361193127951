<template lang='pug'>
v-card.pa-0.stepper-area.transparent(flat)
  v-row.stepper-row(no-gutters)
    v-col.stepper-col(v-for='label, index in labels'
      :key='index'
      jutify='center'
      align='center'
      @click='$emit("click-step", index)')
      step(:data-index='index' :is-selected='isSelected(index)') {{ label }}
</template>

<script>
'use strict'
import Step from '@/components/molecules/Step'

export default {
  props: {
    labels: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      default: 0
    }
  },
  components: {
    Step
  },
  computed: {
    prograssBarStyles () {
      const lastIndex = this.labels.length - 1
      const percentage = ((this.value / lastIndex) * 100)

      return {
        width: `${percentage}%`,
        'border-color': this.$vuetify.theme.themes.light.primary2
      }
    }
  },
  methods: {
    isSelected (index) {
      return index === this.value
    }
  }
}
</script>

<style scoped>
.stepper-col, .stepper-row {
  position: relative;
}

.stepper-row:before {
  content: '';
  width: 100%;
  position: absolute;
  border-top: 2px solid #d8d8d8;
  left: 0;
}

.stepper-row:before {
  bottom: 15%;
}

</style>
