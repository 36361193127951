<template lang='pug'>
v-container.pa-0.emergency-plus
  emergency-plus-header.mb-7

  merit.mb-6
    | こんなところが{{ freelancerLabel }}の方に
    br
    | 喜ばれています。

  emergency-plus-recommended-point.mb-6

  merit.mb-6
    | ご希望に合わせて選べる
    br
    | プラン例

  emergency-plus-example.mb-6(v-bind='routeMeta')

  merit.mb-6
    | しつこい営業もいたしません。
    br
    | お気軽にご相談ください。

  contact-area

  v-row.py-5.accent(no-gutters justify='center' align='center')
    a.label--text(@click='movePageInDirectory("result")') シミュレーション結果に戻る
</template>

<script>
'use strict'

import EmergencyPlusHeader from '@/components/organisms/EmergencyPlusHeader'
import EmergencyPlusRecommendedPoint from '@/components/organisms/EmergencyPlusRecommendedPoint'
import EmergencyPlusExample from '@/components/organisms/EmergencyPlusExample'
import ContactArea from '@/components/organisms/ContactArea'
import Merit from '@/components/molecules/Merit'
import page from '@/mixin/page'
import labels from '@/mixin/labels'

export default {
  mixins: [page, labels],
  computed: {
    routeMeta () {
      return this.$route.meta
    }
  },
  components: {
    EmergencyPlusHeader,
    EmergencyPlusRecommendedPoint,
    EmergencyPlusExample,
    ContactArea,
    Merit
  }
}
</script>
