<template lang='pug'>
v-card.px-3(color='transparent' :height='height' flat)
  v-card.price-card.title(color='white' outlined flat)
    v-card-text.pa-1.title(:class='priceClass') 約{{ value | formatJPUnitPrice }}
  v-card.pb-2.fill-height(:color='color' flat)
    v-row.fill-height(align='end' no-gutters)
      v-col
        v-card-text.pa-0.label-text.subtitle-1.white--text {{ label }}
</template>

<script>
import { formatJPUnitPrice } from '@/utils/formatter'

export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'black'
    },
    height: {
      type: [Number, String],
      default: '100%'
    }
  },
  computed: {
    priceClass () {
      return `${this.color}--text`
    }
  },
  filters: {
    formatJPUnitPrice
  }
}
</script>

<style scoped>
.price-card {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 1;
  width: 100%;
}
</style>
