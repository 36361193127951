import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/src/styles/main.sass'

Vue.use(Vuetify)

export default new Vuetify({
  iconfont: 'md',
  customProperties: true,
  icons: {
    dropdown: 'expand_more'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#ee7e00',
        primary2: '#ea650d',
        primary3: '#e64415',
        secondary: '#999999',
        note: '#96b439',
        'olive-green': '#E3E695',
        default: '#414141',
        warning: '#e02020',
        accent: '#f1edeb',
        label: '#414141',
        placeholder: '#cac7c7',
        background: '#ffffff',
        error: '#b71c1c',
        aqua: '#5389c2',
        line: '#767676',
        twitter: '#00acee',
        hatena: '#006eb3',
        grey: '#767676',
        supplement: '#666666'
      }
    }
  }
})
