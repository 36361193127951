'use strict'
import Vue from 'vue'
import App from './App.vue'

import vuetify from '@/plugins/vuetify'
import '@/plugins/font-awsome-icon.js'
import '@/plugins/gtm'
import '@/plugins/social-sharing'
import router from '@/router/'
import store from '@/store'
import '@/assets/style.scss'
import '@/assets/icomoon/style.css'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
