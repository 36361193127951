<template lang="pug">
ButtonWithIcon.phone-call-button.note.background--text(v-bind='$attrs'
  v-on='$listeners'
  depressed
  :icon='icon'
  :href='telLink')
  slot {{ phoneNumber }}
</template>

<script>
import ButtonWithIcon from '@/components/molecules/ButtonWithIcon'
import { CONTACT_PHONE_NUMBER } from '@/constants'

export default {
  components: {
    ButtonWithIcon
  },
  data () {
    return {
      icon: ['fa', 'phone'],
      phoneNumber: CONTACT_PHONE_NUMBER
    }
  },
  computed: {
    telLink () {
      return `tel:${this.phoneNumber}`
    }
  }
}
</script>
