<template lang='pug'>
v-btn.primary.background--text(v-bind='$attrs'
  v-on='$listeners'
  depressed
  min-heignt='55px'
  large)
  slot
</template>

<script>
export default {
}
</script>

<style scoped>
button >>> .v-btn__content {
  font-size: 1.15rem;
}
</style>
