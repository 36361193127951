<template lang='pug'>
v-card.pa-0.emergency-plus-header(flat)
  v-img.fill-height(src='/img/emergency-plus-image@3x.png'
    ref='image'
    :aspect-ratio='aspectRatio'
    contain
    :gradient='gradient')
  div.message.primary2--text.text-sm-h5.text-body-1.font-weight-bold
    | 自分に何かあった時。
    br
    | 家族のことを考える
    br
    | {{ freelancerLabel }}の方へ。
  v-card(v-show='shouldUseMobileLayout' flat color='transparent' :height='titleBarHeight / 2')
  v-card.title-bar(
    color='primary2'
    :height='titleBarHeight'
    :style='titleBarStyle'
    flat
    tile)
    v-container.pa-0(fill-height)
      v-row(justify='center' no-gutters)
        v-col.white--text.text-body-1.text-sm-h6(cols='auto') エヌエヌ生命の
          br
          div.mt-2.text-h6.text-sm-h5 エマージェンシープラス
</template>

<script>
'use strict'
import labels from '@/mixin/labels'

export default {
  mixins: [labels],
  computed: {
    shouldUseMobileLayout () {
      return this.$vuetify.breakpoint.xsOnly
    },
    aspectRatio () {
      return this.shouldUseMobileLayout ? 4 / 3 : 16 / 9
    },
    gradient () {
      return this.shouldUseMobileLayout ? null : '90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 70%'
    },
    titleBarHeight () {
      return this.shouldUseMobileLayout ? 96 : 179
    },
    titleBarStyle () {
      return this.shouldUseMobileLayout
        ? {
            width: '80%',
            bottom: 0
          }
        : {
            width: '40%',
            top: '50%',
            'margin-top': `${-this.titleBarHeight / 2}px`
          }
    }
  }
}
</script>

<style>
.emergency-plus-header {
  position: relative;
}

.emergency-plus-header > .v-image > .v-image__image {
  background-position: center left !important;
}

.emergency-plus-header .title-bar {
  position: absolute;
  z-index: 1;
  right: 0;
}

.emergency-plus-header .message {
  position: absolute;
  top: 5%;
  left: 5%;
  line-height: 1.6;
}
</style>
