<template lang='pug'>
v-container.px-0.title.text-center
  v-row.mb-5(no-gutters)
    v-col
      div.body-1.mb-3 遺族が必要とする金額
        br
        | を合理的に備えたいあなたには
      colored-text.headline.font-weight-bold
        template(#secondary) 無解約返戻金型収入保障保険
          br
          | がおすすめ！

  v-row.mb-5(no-gutters)
    v-col
      ncvip-cases

  v-row.mb-8(no-gutters)
    v-col
      colored-text.font-weight-bold.headline
        template(#secondary) おすすめする理由

  v-container.pa-0
    v-row.mb-5(align='center' justify='center' no-gutters)
      v-col.col-10(align='center')
        v-img.mb-6(src='/img/moneybag-yen.svg' width='72')
        p ご自身の死亡時でもご家族が
          br
          span.primary2--text 毎月定額
          | を安定的に受け取れる

    v-row.mb-6(align='center' justify='center' no-gutters)
      v-col.col-10(align='center')
        v-img.mb-6(src='/img/pig.svg' width='72')
        p 解約返戻金をなくすことで
          br
          span.primary2--text 保険料が割安

</template>

<script>
'use strict'
import NcvipCases from '@/components/organisms/NcvipCases'
import ColoredText from '@/components/molecules/ColoredText'

export default {
  components: {
    ColoredText,
    NcvipCases
  }
}
</script>
