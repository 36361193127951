<template lang="pug">
annotation(:title='title')
  colored-text.prerequisite-title.mb-3 遺族の生活費・教育費：前提条件

  ul.detail-list.mb-3
    li 年収580万、配偶者年齢40歳、末子4歳の場合
    li 生活費は総務省「家計調査報告(家計収支編)2019年平均結果の概要」を元に、一般社団法人生命保険協会が提供する必要保障額積立方式にて算出
    li 教育費は幼稚園～高校までは公立を前提に文部科学省「平成30年 子供の学習費調査」を元に算出、大学は国立の学費・入学金から算出
    li 結婚費用、住居費用、葬儀費用、相続費用等は未考慮

</template>

<script>
import Annotation from '@/components/molecules/Annotation'
import ColoredText from '@/components/molecules/ColoredText'

export default {
  components: {
    Annotation,
    ColoredText
  },
  props: {
    title: {
      type: String,
      default: '金額算出の主な前提条件を読む'
    }
  }
}
</script>

<style lang="scss" scoped>
.prerequisite-title {
  font-size: 1rem;
}

.detail-list {
  font-size: 1rem;
  line-height: 1.6;
}
</style>
