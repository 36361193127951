'use strict'

const NCVIP_META = {
  product: 'ncvip',
  estimationAppDir: 'ncvip'
}

const SITP_META = {
  product: 'sitp',
  estimationAppDir: 'sitp'
}

const SITP_EXAMPLES = [
  {
    text: 'ご夫婦２人のみ',
    baseAmount: 1000 * 10000,
    icon: 'icon-parent'
  },
  {
    text: 'ご夫婦＋お子様２人',
    baseAmount: 2000 * 10000,
    icon: 'icon-family'
  }
]

export default {
  'ncvip-a1': {
    ...NCVIP_META,
    termKindCode: 1,
    period: 5,
    examples: [
      {
        monthlyPensionAmount: 10 * 10000
      },
      {
        monthlyPensionAmount: 20 * 10000
      }
    ]
  },

  'ncvip-a2': {
    ...NCVIP_META,
    termKindCode: 2,
    period: 65,
    examples: [
      {
        monthlyPensionAmount: 10 * 10000
      },
      {
        monthlyPensionAmount: 20 * 10000
      }
    ]
  },

  'sitp-a1': {
    ...SITP_META,
    termKindCode: 1,
    period: 5,
    examples: SITP_EXAMPLES
  },

  'sitp-a2': {
    ...SITP_META,
    termKindCode: 2,
    period: 65,
    examples: SITP_EXAMPLES
  }
}
