<template lang='pug'>
span.label-with-loading
  colored-text(v-if='value')
    slot {{ value }}
  colored-text(v-else)
    v-progress-circular(
      indeterminate
      size=24
      :color='color')
</template>

<script>
import ColoredText from '@/components/molecules/ColoredText'

export default {
  props: {
    value: {
      type: [String, Number]
    },
    color: {
      type: String,
      default: 'primary2'
    }
  },
  components: {
    ColoredText
  }
}
</script>
