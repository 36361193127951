<template lang='pug'>
v-container.pa-0
  v-btn.primary2.title.white--text(
    outlined
    block
    min-height='80'
    @click.stop='onClickButton')
    v-icon(size='40px').mr-4 icon-money
    | 保険料を見る
  DialogContent(v-model='dialog' :title='title' @close='hideDialog')
    v-layout(flex column)
      div.mb-4 {{ nameOfDescription || jpName }}はお電話（{{ phoneNumber }}）もしくはメール（{{ emailAddress }}）でご連絡ください。
      PhoneButton.mb-3(@click="$emit('click-phone-button')") 電話でご相談
      MailButton.mb-3(@click="$emit('click-mail-button')")
      PrivacyPolicyLink.privacy-policy-link
</template>

<script>
import Button from '@/components/atoms/Button'
import DialogContent from '@/components/molecules/DialogContent'
import ColoredText from '@/components/molecules/ColoredText'
import PhoneButton from '@/components/molecules/PhoneButton'
import MailButton from '@/components/molecules/MailButton'
import PrivacyPolicyLink from '@/components/molecules/PrivacyPolicyLink'
import { CONTACT_PHONE_NUMBER, CONTACT_EMAIL_ADDRESS } from '@/constants'

export default {
  components: {
    Button,
    DialogContent,
    ColoredText,
    PhoneButton,
    MailButton,
    PrivacyPolicyLink
  },
  props: {
    name: {
      type: String,
      requird: true,
      default: '保険相談'
    },
    label: {
      type: String,
      requird: true
    },
    jpName: {
      type: String,
      requird: true
    },
    nameOfDescription: String
  },
  data () {
    return {
      icon: ['fa', 'external-link-alt'],
      phoneNumber: CONTACT_PHONE_NUMBER,
      emailAddress: CONTACT_EMAIL_ADDRESS,
      dialog: false
    }
  },
  computed: {
    title () {
      return `${this.name}は準備中です。`
    }
  },
  methods: {
    onClickButton () {
      this.dialog = true
      this.$emit('click-apply-button')
    },
    hideDialog () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.privacy-policy-link {
  font-size: 0.9rem;
}
</style>
