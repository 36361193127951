<template lang='pug'>
v-container.emergency-plus-recommended-point.accent(fill-height)
  v-row(no-gutters)
    v-col(v-for='item, index in recommendedPointItems'
      cols=12
      :sm='12 / recommendedPointItems.length'
      :key='index + 1')
      recommended-point(
        v-bind='item'
        :class='getClass(index)'
        @toggle='sendToggleLog(index)')
</template>

<script>
'use strict'

import page from '@/mixin/page'
import RecommendedPoint from '@/components/molecules/RecommendedPoint'

export default {
  components: {
    RecommendedPoint
  },
  mixins: [page],
  computed: {
    recommendedPointItems () {
      return [
        {
          title: '保険料がリーズナブル',
          subtitle: '安心の保障内容なのに',
          text: '家族のための保険とはいえ、あまり保険料が高いのはしっくりこなかったんです。',
          icon: 'icon-wallet',
          illustrationImage: this.reqirePNGImage('emergency-plus-person-01@3x')
        },
        {
          title: 'ネットでカンタン申込',
          subtitle: '病歴があっても大丈夫',
          text: '病歴があったので心配していたけど、簡単な質問に応えるだけで申込できました。',
          icon: 'icon-phone-laptop',
          illustrationImage: this.reqirePNGImage('emergency-plus-person-02@3x')
        },
        {
          title: 'フリーランス協会も採用',
          subtitle: '会員特典に掲載',
          text: '会社組織には属していないので、お世話になっている協会での採用は安心できますね。',
          icon: 'icon-award',
          illustrationImage: this.reqirePNGImage('emergency-plus-person-03@3x')
        }
      ]
    }
  },
  methods: {
    reqirePNGImage (name) {
      return require(`@/assets/${name}.png`)
    },
    getClass (index) {
      const isNotLast = (index < (this.recommendedPointItems.length - 1))
      return {
        'mb-3': isNotLast,
        'mb-sm-0': true,
        'mr-sm-3': isNotLast
      }
    },
    sendToggleLog (index) {
      this.sendLog(`toggle-emergency-plus-recommended-point-accordion-${index}`)
    }
  }
}
</script>
