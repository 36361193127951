<template lang='pug'>
v-card.py-4.px-3(outlined)
  v-container.pa-0(fluid)
    v-row(dense)
      v-col
        v-radio-group.my-0.mb-2(v-model='sex'
          row
          mandatory
          hide-details)
          v-radio(v-for='item in sexSelectItems' :value='item.value')
            template(#label)
              colored-text.personal-information {{ item.label }}
    v-row(dense)
      v-col
        colored-text.personal-information.mb-2 {{ age }}歳

    v-row(v-if='errorMessage' no-gutters)
      v-col
        v-alert.mb-0(type='error' outlined dense) {{ errorMessage }}

    template(v-else-if='isSitp')
      v-row(align='center' dense)
        v-col.title 月額保険料相当額
        v-col(align='right')
          label-with-loading.annual-payment(v-model='monthlyInsurancePremium') {{ monthlyInsurancePremium | formatJPPrice }}/月

      v-row(align='center' dense)
        v-col.title 年額保険料
        v-col(align='right')
          label-with-loading.annual-payment(v-model='insurancePremium') {{ insurancePremium | formatJPPrice }}/年

    template(v-else)
      v-row(align='center' dense)
        v-col.title 月額保険料
        v-col(align='right')
          label-with-loading.annual-payment(v-model='insurancePremium') {{ insurancePremium | formatJPPrice }}/月

    v-row.pa-3
      v-container.pa-0.label--text.subtitle-1(fluid)
        v-row.title(v-for='item in contractDetails' no-gutters)
          v-col.col-7.data-key {{ item.title }}
          v-col.data-value(align='right') {{ item.value }}

    v-row(v-if='isSitp')
      supplementary-explanation.px-3
        | ※不慮の事故・感染症および急性心筋梗塞・脳卒中などによる突然の死亡以外の場合は{{ deathInsurancePremium | formatJPUnitPrice }}支払われます。
</template>

<script>
'use strict'

import ColoredText from '@/components/molecules/ColoredText'
import LabelWithLoading from '@/components/molecules/LabelWithLoading'
import SupplementaryExplanation from '@/components/atoms/SupplementaryExplanation'
import { getSexLabel, formatJPPrice, formatJPUnitPrice } from '@/utils/formatter'
import { mapFields } from 'vuex-map-fields'

export default {
  props: {
    product: {
      type: String,
      required: true
    },
    age: {
      type: [String, Number],
      required: true
    },
    period: {
      type: [String, Number],
      required: true
    },
    expirationAge: {
      type: [String, Number],
      required: false
    },
    termKindCode: {
      type: [String, Number],
      required: true
    },
    baseAmount: {
      type: [String, Number],
      required: false
    },
    monthlyPensionAmount: {
      type: [String, Number],
      required: false
    }
  },
  components: {
    ColoredText,
    LabelWithLoading,
    SupplementaryExplanation
  },
  data () {
    return {
      insurancePremium: 0,
      errorMessage: null,
      sexSelectItems: [
        {
          label: '男性',
          value: 1
        },
        {
          label: '女性',
          value: 2
        }
      ]
    }
  },
  mounted () {
    this.updateStatus()
  },
  watch: {
    sex () {
      this.updateStatus()

      this.$emit('update-sex', this.sex)
    }
  },
  methods: {
    async updateStatus () {
      try {
        const { result } = await this.$store.dispatch('calcInsurancePremium', {
          product: this.product,
          inputs: {
            age: this.age,
            baseAmount: this.baseAmount,
            monthlyPensionAmount: this.monthlyPensionAmount,
            termInsured: this.period,
            sex: this.sex
          }
        })

        this.insurancePremium = result[0].estimatedAmount
      } catch (error) {
        this.errorMessage = error.response.data.message
      }
    }
  },
  computed: {
    ...mapFields(['sex']),
    isSitp () {
      return this.product === 'sitp'
    },
    shouldUseExpirationYear () {
      return this.termKindCode === 1
    },
    monthlyInsurancePremium () {
      return (this.insurancePremium / 12)
    },
    deathInsurancePremium () {
      return (this.baseAmount / 2)
    },
    periodText () {
      return (this.shouldUseExpirationYear) ? `${this.period}年` : `${this.expirationAge}歳満了`
    },
    contractDetails () {
      const { isSitp } = this
      return [
        {
          title: isSitp ? '基準保険金額' : '年金月額',
          value: this.$options.filters.formatJPUnitPrice(this.baseAmount || this.monthlyPensionAmount)
        },
        {
          title: '保険期間/保険料払込期間',
          value: this.periodText
        },
        {
          title: '保険料払込方法',
          value: isSitp ? '年払' : '月払'
        }
      ]
    }
  },
  filters: {
    getSexLabel,
    formatJPUnitPrice,
    formatJPPrice
  }
}
</script>

<style lang='scss' scoped>
.personal-information {
  font-size: 1.5rem !important;
  font-weight: 600;
}

.annual-payment {
  font-size: 1.8rem !important;
  font-weight: 600;
  white-space: nowrap;
}
</style>
