'use strict'

export const PRIVACY_POLICY_URL = 'https://www.nnlife.co.jp/company/policies/privacy'
export const APP_TITLE = '遺族保障シミュレーション'
export const SERVER_ERROR_MESSAGE = '予期せぬエラーが発生しました。再試行してください。'
export const STORAGE_KEY = 'simulation-tool-for-lancers'
export const MIN_AGE = 20
export const MAX_AGE = 70
export const CONTACT_PHONE_NUMBER = '0120-710-347'
export const CONTACT_EMAIL_ADDRESS = 'info_bizd@nnlife.co.jp'
export const CONTACT_LINE_URL = 'https://lin.ee/4igJMXqZK'
export const B_PATTERN_DIR_NAME = 'b'
