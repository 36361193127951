<template lang='pug'>
v-text-field(v-bind='$attrs'
  v-on='$listeners'
  background-color='white'
  outlined)
</template>

<script>
export default {
}
</script>

<style lang='scss' scoped>

</style>
