<template lang="pug">
Content(:isHome='true')
  template(#header)
    span.headline.font-weight-bold {{ title }}
  template(#description)
    div.text-center {{ freelancerLabel }}の遺族保障はどれだけ少ない？
      br
      | さっそく試算してみましょう！

  v-divider.mt-5
  v-container.mt-5.mb-15
    simulation-form(@success='onSubmitForm')
</template>

<script>
import Content from '../components/organisms/Content'
import SimulationForm from '../components/organisms/SimulationForm'
import labels from '@/mixin/labels'
import page from '@/mixin/page'
import { APP_TITLE } from '@/constants'

export default {
  mixins: [page, labels],
  components: {
    Content,
    SimulationForm
  },
  computed: {
    title () {
      return APP_TITLE
    }
  },
  methods: {
    onSubmitForm () {
      this.movePageInDirectory('result')
    }
  }
}
</script>

<style lang="scss" scoped>
.header-text {
  font-size: 1.2rem;
}
</style>
