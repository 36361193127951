'use strict'

import client from './client'

export default {
  simulation (data) {
    return client.post('/simulations/survivor-pensions', data)
  },
  contact (data) {
    return client.post('/contacts', data)
  },
  log (data) {
    return client.post('/logs', data)
  },
  businessRule (name) {
    return client.get(`/business-rules/${name}`)
  },
  calculation (data) {
    return client.post(`/calculations/insurance-premiums/${data.product}`, data)
  },
  question () {
    return client.get('/diagnosis-questions/osusume-hoken-shindan')
  },
  diagnosis (data) {
    return client.post('/diagnoses', data)
  }
}
