<template lang='pug'>
v-card(flat color='transparent' :height='height')
  v-row.fill-height(align='end' no-gutters)
    v-col.mx-5
      PriceBar.freelancer-bar(
        v-bind='dataset.freelancer'
        :label='createLabel(freelancerLabel)')
    v-col.mx-5
      PriceBar.freelancer-bar(
        v-bind='dataset.employee'
        :label='createLabel("会社員")')
</template>

<script>
import PriceBar from '@/components/molecules/PriceBar'
import labels from '@/mixin/labels'

export default {
  mixins: [labels],
  components: {
    PriceBar
  },
  props: {
    freelancer: {
      type: Number,
      required: true
    },
    employee: {
      type: Number,
      required: true
    },
    height: {
      type: [String, Number],
      default: 320
    }
  },
  computed: {
    dataset () {
      const {
        freelancer,
        employee
      } = this

      return {
        freelancer: {
          value: freelancer,
          color: 'aqua',
          height: this.calculateHeight(freelancer, employee)
        },
        employee: {
          value: employee,
          color: 'note',
          height: this.calculateHeight(employee, freelancer)
        }
      }
    }
  },
  methods: {
    calculateHeight (value1, value2) {
      const { height } = this
      const barHeight = value1 > value2 ? height : height * (value1 / value2)
      const minHeight = (height / 2)

      return barHeight > minHeight ? barHeight : minHeight
    },
    createLabel (name) {
      return `${name}の遺族の受取額`
    }
  }
}
</script>
