<template lang='pug'>
v-container.olive-green.contact-area.pa-0.d-flex(fluid)
  v-row(no-gutters align='center' justify='center')
    v-col.col-sm-5.col-12(align='center')
      v-row(no-gutters align='center' justify='center')
        v-col.my-5.col-5(v-for='setting in buttonSettings'
          align='center'
          :key='setting.name')
          v-card.pa-0(color='transparent' flat
            @click='sendLog(`click-${setting.name}-button`)'
            :href='setting.href'
            :target='setting.target')
            v-img(width=100 :src='setting.icon')
            div.font-weight-bold.default--text {{ setting.name }}で相談

        v-col.col-7.py-sm-10.text-body-1
          strong 「申込資格にあうかな…？」
            br
            | 「家族にいくら残せる…？」
          div まずはお問い合わせください。

        // For smartphone
        v-col.col-5(v-show='shouldChangeLayout')
          v-img(src='@/assets/operator.svg')

    v-col.col-5.pa-sm-10(v-show='!shouldChangeLayout')
      v-img(width=240 src='@/assets/operator.svg')
</template>

<script>

import page from '@/mixin/page'
import { CONTACT_PHONE_NUMBER, CONTACT_LINE_URL } from '@/constants'

export default {
  mixins: [page],
  computed: {
    shouldChangeLayout () {
      return this.$vuetify.breakpoint.xsOnly
    },
    buttonSettings () {
      return [
        {
          name: 'LINE',
          icon: require('@/assets/line.svg'),
          href: CONTACT_LINE_URL,
          target: '_blank'
        },
        {
          name: '電話',
          icon: require('@/assets/phone.svg'),
          href: `tel:${CONTACT_PHONE_NUMBER}`
        }
      ]
    }
  }
}
</script>
