<template lang='pug'>
v-container.pa-0.title.text-center
  v-row.mb-5(no-gutters)
    v-col
      colored-text.mb-5.headline.font-weight-bold
        | {{ freelancerLabel }}の方には
        br
        template(#secondary) エマージェンシープラスがおすすめ！
      div.px-3
        strong 突然のリスク
        | にそなえるならエヌエヌ生命のエマージェンシープラス

  v-row.mb-8(no-gutters)
    v-col
      sitp-cases

  v-row.mb-8(no-gutters)
    v-col
      colored-text.font-weight-bold.headline
        template(v-if='isInBDirectory' #secondary) おすすめする理由
        template(v-else #secondary) フリーランスにおすすめする理由
  v-row.mb-6(align='center' justify='center' no-gutters)
    v-col.col-10(align='center')
      v-img.mb-7(src='/img/pig.svg' width='72')
      p 事業でお金がかかるので解約返戻
        br
        | 金をなくして
        span.primary2--text 割安な保険料

  v-row.mb-6(align='center' justify='center' no-gutters)
    v-col.col-10(align='center')
      v-img.mb-6(src='/img/laptop.svg' width='78')
      p 日々の業務が忙しい / 感染症が心配
        br
        | でも
        span.primary2--text インターネットでお申込み可

  v-row.mb-6(v-if='!isInBDirectory' align='center' justify='center' no-gutters)
    v-col.col-10(align='center')
      v-img.mb-5(src='/img/good-thumbs.svg' width='64')
      p フリーランス協会の
        br
        span.primary2--text ベネフィットプラン
        | にも採用
</template>

<script>
'use strict'

import labels from '@/mixin/labels'
import SitpCases from '@/components/organisms/SitpCases'
import ColoredText from '@/components/molecules/ColoredText'

export default {
  components: {
    ColoredText,
    SitpCases
  },
  mixins: [labels]
}
</script>
