<template lang='pug'>
v-btn.button-with-icon.ma-0(v-bind='$attrs'
  v-on='$listeners'
  tag='a'
  align-center
  large)
  div.icon-image.mr-3(:width='iconSize')
    font-awesome-icon(v-if='isFontAwesomeIcon' :width='iconSize' :icon='icon')
    v-icon(v-else :size='iconSize') {{ icon }}
  slot
</template>

<script>
import buttonWithIcon from '@/mixin/button-with-icon'

export default {
  mixins: [buttonWithIcon],
  props: {
    iconSize: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>

<style lang='scss' scoped>
.icon-image {
  color: inherit;
}

.icon-image i {
  font-size: 1.6rem;
  color: inherit;
}

.button-with-icon {
  font-size: 1.3rem;
}
</style>
