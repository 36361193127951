<template lang='pug'>
v-container.accent.cases
  colored-text.text-center.headline どのような場合に保険金が
    br
    | 支払われますか？
    sup.default--text *1*2
  v-row
    v-col
      CaseCard(image='/img/health.svg')
        template(#text) 死亡した場合
  v-row
    v-col
      CaseCard(image='/img/human.svg')
        template(#text) 急性心筋梗塞または脳卒中により死亡した場合
    v-col
      CaseCard(image='/img/collapsed_house.svg')
        template(#text) 不慮の事故または感染症により死亡した場合

  v-row(dense)
    v-col.text-center
      a.title.default--text(href='/pdf/sitp-overview.pdf' target='_blank') エマージェンシープラスの詳細は「契約概要」「注意喚起情報」「ご契約のしおり・約款」をご覧ください

  v-row.secondary--text.mb-3.body-1(dense)
    v-col
      | ＊１：この保険には支払削減期間があります。ご契約日からその日を含めて1年以内に重度疾病死亡保険金または死亡保険金のお支払事由に該当した場合、お支払する金額が半額に削減されます。
      br
      | ＊２：重度疾病死亡保険金または災害死亡保険金が支払われる場合は死亡保険金はお支払いしません。
</template>

<script>
'use strict'

import ColoredText from '@/components/molecules/ColoredText'
import CaseCard from '@/components/molecules/CaseCard'

export default {
  components: {
    CaseCard,
    ColoredText
  }
}
</script>
