<template lang='pug'>
Content
  ncvip-recommended-points(v-if='isNcvip')
  sitp-recommended-points(v-else)

  headline.text-center.headline ご契約例
  example.mb-3(v-bind='routeMeta')

  headline.mt-5.text-center.headline
    colored-text.mb-2 エヌエヌ生命の
      br
      | 無料相談をご活用ください！

  icon-list(title='当社の強み' icon='done' v-model='strengthListItem')

  headline.mt-5.text-center.headline 専門家に何の相談を出来ますか？

  icon-list(icon='icon-question' v-model='questionListItem')

  v-container.mb-5.pa-0
    v-row.mb-5(dense)
      v-col(align='center')
        a.default--text.body-1(href='https://www.nnlife.co.jp/' target='_blank') 運営会社: エヌエヌ生命保険株式会社

  v-footer.accent.pb-16
    v-col.pb-16.mb-16(align='center')
      a.default--text.body-1(@click='movePageInDirectory("result")') 前のページへ戻る

  reservation-button-navigation
</template>

<script>
import Headline from '@/components/atoms/Headline'
import Description from '@/components/atoms/Description'
import ColoredText from '@/components/molecules/ColoredText'
import SitpRecommendedPoints from '@/components/organisms/SitpRecommendedPoints'
import NcvipRecommendedPoints from '@/components/organisms/NcvipRecommendedPoints'
import ReservationButtonNavigation from '@/components/organisms/ReservationButtonNavigation'
import IconList from '@/components/molecules/IconList'
import Content from '@/components/organisms/Content'
import Example from '@/components/organisms/Example'
import page from '@/mixin/page'
import labels from '@/mixin/labels'

export default {
  mixins: [page, labels],
  components: {
    SitpRecommendedPoints,
    NcvipRecommendedPoints,
    ReservationButtonNavigation,
    Headline,
    IconList,
    Description,
    Example,
    ColoredText,
    Content
  },
  data () {
    return {
      strengthListItem: [
        {
          text: 'スモールビジネスに寄り添って30年以上',
          link: {
            text: '今般のコロナ禍における保険契約者への貸付の金利減免をいち早く打ち出しメディアに掲載されました（日経電子版）',
            href: 'https://www.nikkei.com/article/DGXMZO56513630W0A300C2EA4000/'
          }
        },
        {
          text: '幅広いニーズを満たす豊富な商品ラインナップ'
        }
      ]
    }
  },
  computed: {
    routeMeta () {
      return this.$route.meta
    },
    questionListItem () {
      return [
        {
          text: `${this.freelancerLabel}の遺族保障について詳しく知りたい時`
        },
        {
          text: 'どの保険商品が良いのかよく分からない時'
        },
        {
          text: '他の保険会社で加入できないと言われたけれど、エヌエヌなら加入できるかどうか知りたい時'
        }
      ]
    },
    isNcvip () {
      return (this.routeMeta.product === 'ncvip')
    },
    estimationAppUrl () {
      return `${process.env.VUE_APP_PREMIUM_ESTIMATION_URL}/${this.routeMeta.estimationAppDir}`
    }
  },
  methods: {
    onClickContactButton () {
      this.movePageInDirectory('contact')
    },
    onClickDocumentRequestButton () {
      this.sendLog('click-document-request-button')
    }
  }
}
</script>
